import "bootstrap/dist/css/bootstrap.min.css";
import { ReactComponent as LeftArrow } from "../../../../Assets/common/images/leftArrow.svg";
import LoadingOverlay from "react-loading-overlay-ts";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useNavigate, useParams } from "react-router-dom";
import {
  changeActiveComponent,
  confirmOtp,
  fillout,
  getDocumentsPagare,
  getFieldsPagare,
  getPagareById,
  resetFields,
  resetPagareDetail,
  selectActivePagareComponent,
  selectConfirmationId,
  selectCurrentFields,
  selectCurrentPagare,
  selectFilledOut,
  selectLoadingDocuments,
  selectLoadingFields,
  selectLoadingPagares,
  selectOtpLoading,
  selectSendAgain,
  selectSentOtp,
  sendOtpConfirm,
  setValuesFields,
} from "../pagareSlice";
import ClipLoader from "react-spinners/ClipLoader";
import { SmallSquare } from "../../firma/detail/components/Squares";
import {
  getStatusPagareName,
  getTipoPagareName,
} from "../../firma/helpers/utils";
import { SimpleInput } from "./components/SimpleInput";
import OtpInput from "react-otp-input-rc-17";
import Modal, { Styles } from "react-modal";

const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    height: "30%",
    width: "30%",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "2%",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};

const customStylesModalDeudores: Styles = {
  content: {
    top: "50%",
    left: "60%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "1.2%",
    width: "75%",
    overflow: "auto",
  },
  overlay: {
    background: "rgba(0,0,0,0.3)",
  },
};
Modal.setAppElement("#root");

export const PagareFill = () => {
  let loadingStatus = useAppSelector(selectLoadingPagares);
  let documentsLoading = useAppSelector(selectLoadingDocuments);
  let loadingStatusFields = useAppSelector(selectLoadingFields);
  let currentPagare = useAppSelector(selectCurrentPagare);
  let currentFields = useAppSelector(selectCurrentFields);
  let sentOtp = useAppSelector(selectSentOtp);
  let otpLoading = useAppSelector(selectOtpLoading);
  let sendAgain = useAppSelector(selectSendAgain);
  let confirmationId = useAppSelector(selectConfirmationId);
  let filledOut = useAppSelector(selectFilledOut);
  let activeComponent = useAppSelector(selectActivePagareComponent);

  const [otpValue, setOtpValue] = useState("");
  const [showResend, setShowResend] = useState(false);
  const [beginCounter, setBeginCounter] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [goingBack, setGoingBack] = useState(false);
  const [deudoresModalOpen, setDeudoresModalOpen] = useState(false);

  const closeModal = () => {
    setModalIsOpen(false);
    dispatch(resetPagareDetail());
    navigate(-1);
  };

  const [values, setValues] = useState([{ id: "", value: "" }]);
  const [disabledInputs, setDisabledInputs] = useState(false);

  let navigate = useNavigate();
  let dispatch = useAppDispatch();

  let { id } = useParams();

  const getOtherDeudores = () => {
    let otherDeudores = [];
    if (currentPagare && currentPagare?.deudores.length > 1) {
      otherDeudores = currentPagare?.deudores.slice(1);
      return (
        <table style={{ marginTop: "10px", width: "100%" }}>
          <thead>
            <th style={{ color: "black", fontWeight: 600 }}>Nombre</th>
            <th style={{ color: "black", fontWeight: 600 }}>Apellidos</th>
            <th style={{ color: "black", fontWeight: 600 }}>
              No. de Documento
            </th>
            <th style={{ color: "black", fontWeight: 600 }}>Correo</th>
            <th style={{ color: "black", fontWeight: 600 }}>No. Celular</th>
            <th style={{ color: "black", fontWeight: 600 }}>
              ID Identidad Digital
            </th>
          </thead>
          <tbody>
            {otherDeudores.map((deudor, index) => {
              return (
                <tr key={index}>
                  <td
                    style={{
                      color: "black",
                      fontWeight: 500,
                      fontSize: "15px",
                    }}
                  >
                    {deudor.nombre}
                  </td>
                  <td
                    style={{
                      color: "black",
                      fontWeight: 500,
                      fontSize: "15px",
                    }}
                  >
                    {deudor.apellido}
                  </td>
                  <td
                    style={{
                      color: "black",
                      fontWeight: 500,
                      fontSize: "15px",
                    }}
                  >
                    {deudor.identification}
                  </td>
                  <td
                    style={{
                      color: "black",
                      fontWeight: 500,
                      fontSize: "15px",
                    }}
                  >
                    {deudor.email}
                  </td>
                  <td
                    style={{
                      color: "black",
                      fontWeight: 500,
                      fontSize: "15px",
                    }}
                  >
                    {deudor.phoneNumber}
                  </td>
                  <td
                    style={{
                      color: "black",
                      fontWeight: 500,
                      fontSize: "15px",
                    }}
                  >
                    {deudor.userId}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    } else {
      return <div></div>;
    }
  };

  useEffect(() => {
    if (currentFields.length === 0 && id !== "" && !goingBack) {
      dispatch(getFieldsPagare(id || ""));
    }

    if ((!currentPagare || currentPagare.id !== id) && id !== "") {
      dispatch(getPagareById(id || ""));
    }

    if (currentPagare && documentsLoading === "idle") {
      dispatch(getDocumentsPagare(currentPagare.id));
    }

    if (filledOut) {
      setModalIsOpen(true);
    }

    if (currentFields.length > 0 && values.length === 1) {
      let valuesTemp: { id: string; value: string }[] = [];
      for (const field of currentFields) {
        if (field.value) {
          valuesTemp.push({ id: field.id, value: field.value });
        } else {
          valuesTemp.push({ id: field.id, value: "" });
        }
      }

      setValues(valuesTemp);
    }

    if (sentOtp) {
      setDisabledInputs(true);
    }

    if (confirmationId && confirmationId !== "") {
      dispatch(fillout(confirmationId));
    }

    let timer = setTimeout(() => {
      if (beginCounter) {
        setShowResend(true);
      }
    }, 16000);
    if (activeComponent == "none") {
      dispatch(changeActiveComponent("none"));
    }
    return () => {
      clearTimeout(timer);
    };
  }, [id, currentFields, showResend, beginCounter, confirmationId, sentOtp]);

  return (
    <LoadingOverlay
      active={
        loadingStatus === "pending" ||
        loadingStatusFields === "pending" ||
        otpLoading === "pending"
      }
      spinner={<ClipLoader color="white" size="100px" />}
    >
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStylesModal}
        contentLabel="Error de búsqueda"
        shouldCloseOnOverlayClick={true}
      >
        <div
          className="flex-col"
          style={{
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div
            className="text-medium text-normal"
            style={{
              color: "black",
              marginBottom: "10px",
              paddingLeft: "10%",
              paddingRight: "10%",
            }}
          >
            Se ha diligenciado el pagaré de manera correcta y segura.
          </div>
          <button
            className="text-medium text-bold primary-color"
            style={{
              border: "none",
              textAlign: "center",
              borderRadius: "8px",
              background: "#1A617D",
              paddingTop: "5px",
              paddingBottom: "5px",
              paddingRight: "10%",
              paddingLeft: "10%",
            }}
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          >
            Cerrar
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={deudoresModalOpen}
        style={customStylesModalDeudores}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          setDeudoresModalOpen(false);
        }}
      >
        <div className="flex-col">
          <div
            className="text-large text-bold"
            style={{ textAlign: "center", fontSize: "18px" }}
          >
            Codeudores
          </div>
          {getOtherDeudores()}
          <div
            className="flex-row"
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <div
              onClick={(e) => {
                e.preventDefault();
                setDeudoresModalOpen(false);
              }}
              className="flex-col text-medium text-bold primary-color"
              style={{
                background: "#1A617D",
                width: "20%",
                height: "30px",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              Cerrar
            </div>
          </div>
        </div>
      </Modal>
      {currentPagare && loadingStatus === "resolved" ? (
        <div
          className="flex-col"
          style={{ height: "100%", overflow: "auto", width: "100%" }}
        >
          <div
            className="flex-row"
            style={{
              height: "10%",
              justifyContent: "end",
              alignItems: "center",
              marginRight: "5%",
            }}
          >
            <div
              onClick={(e) => {
                e.preventDefault();
                setGoingBack(true);
                dispatch(resetFields());
                navigate(-1);
              }}
              className="flex-row"
              style={{
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <LeftArrow style={{ marginRight: "15px" }} />
              <div className="text-bold text-xx-large primary-color">
                Regresar al detalle del pagaré
              </div>
            </div>
          </div>
          <div className="flex-row" style={{ height: "100%", width: "100%" }}>
            <div
              className="flex-col"
              style={{
                height: "85%",
                width: "44%",
                justifyContent: "start",
                padding: "1%",
              }}
            >
              <div
                className="flex-row"
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "33%",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "rgba(0,0,0,0.2)",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "38px",
                      backgroundColor: "rgba(0,0,0,0.2)",
                      borderRadius: "8px 8px 0px 0px",
                    }}
                  >
                    <h6
                      style={{
                        color: "rgba(255,255,255,0.4)",
                        textAlign: "center",
                        margin: "0px",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      Estado
                    </h6>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "70px",
                      borderRadius: "0px 0px 8px 8px",
                    }}
                  >
                    <h6
                      style={{
                        color: "white",
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "600",
                        margin: "0px",
                      }}
                    >
                      {getStatusPagareName(currentPagare.status)}
                    </h6>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "46%",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "rgba(0,0,0,0.2)",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "38px",
                      backgroundColor: "rgba(0,0,0,0.2)",
                      borderRadius: "8px 8px 0px 0px",
                    }}
                  >
                    <h6
                      style={{
                        color: "rgba(255,255,255,0.4)",
                        textAlign: "center",
                        margin: "0px",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      Tipo de Pagaré
                    </h6>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "70px",
                      borderRadius: "0px 0px 8px 8px",
                    }}
                  >
                    <h6
                      style={{
                        color: "white",
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "600",
                        margin: "0px",
                        padding: "0px 5px 0px 5px",
                      }}
                    >
                      {getTipoPagareName(currentPagare.type)}
                    </h6>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "18%",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "rgba(0,0,0,0.2)",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "38px",
                      backgroundColor: "rgba(0,0,0,0.2)",
                      borderRadius: "8px 8px 0px 0px",
                    }}
                  >
                    <h6
                      style={{
                        color: "rgba(255,255,255,0.4)",
                        textAlign: "center",
                        margin: "0px",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      Deudores
                    </h6>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "70px",
                      borderRadius: "0px 0px 8px 8px",
                    }}
                  >
                    <h6
                      style={{
                        color: "white",
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "600",
                        margin: "0px",
                      }}
                    >
                      {currentPagare.deudores.length}
                    </h6>
                  </div>
                </div>
              </div>

              {currentPagare.deudores && currentPagare.deudores.length > 0 && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "8px",
                    alignItems: "center",
                    backgroundColor: "rgba(0,0,0,0.2)",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "8px 8px 0px 0px",
                      background: "rgba(0,0,0,0.2)",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "30px",
                    }}
                  >
                    <h6
                      style={{
                        color: "rgba(255,255,255,0.4)",
                        fontSize: "14px",
                        margin: "0px",
                      }}
                    >
                      Datos de deudores
                    </h6>
                  </div>
                  <div
                    style={{
                      width: "96%",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "8px",
                      background: "rgba(0,0,0,0.2)",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "10px 0px",
                      padding: "10px 10px",
                    }}
                  >
                    {currentPagare.deudores &&
                      currentPagare.deudores.length > 0 && (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <div
                            style={{
                              width: "50%",
                              textAlign: "start",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {currentPagare.deudores[0].nombre && (
                              <h5
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                              >
                                Nombre:
                              </h5>
                            )}
                            {currentPagare.deudores[0].apellido && (
                              <h5
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                              >
                                Apellido:
                              </h5>
                            )}
                            {currentPagare.deudores[0].identification && (
                              <h5
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                              >
                                No. de documento:
                              </h5>
                            )}
                            {currentPagare.deudores[0].phoneNumber && (
                              <h5
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                              >
                                Teléfono:
                              </h5>
                            )}
                            {currentPagare.deudores[0].email && (
                              <h5
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                              >
                                Correo Electrónico:
                              </h5>
                            )}
                            {currentPagare.deudores[0].userId && (
                              <h5
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                              >
                                ID Identidad Digital:
                              </h5>
                            )}
                          </div>
                          <div
                            style={{
                              width: "50%",
                              textAlign: "start",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {currentPagare.deudores[0].nombre && (
                              <h5
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                }}
                              >
                                {currentPagare.deudores[0].nombre}
                              </h5>
                            )}
                            {currentPagare.deudores[0].apellido && (
                              <h5
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                }}
                              >
                                {currentPagare.deudores[0].apellido}
                              </h5>
                            )}
                            {currentPagare.deudores[0].identification && (
                              <h5
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                }}
                              >
                                {currentPagare.deudores[0].identification}
                              </h5>
                            )}
                            {currentPagare.deudores[0].phoneNumber && (
                              <h5
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                }}
                              >
                                {currentPagare.deudores[0].phoneNumber}
                              </h5>
                            )}
                            {currentPagare.deudores[0].email && (
                              <h5
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                }}
                              >
                                {currentPagare.deudores[0].email}
                              </h5>
                            )}
                            {currentPagare.deudores[0].userId && (
                              <h5
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                }}
                              >
                                {currentPagare.deudores[0].userId}
                              </h5>
                            )}
                          </div>
                        </div>
                      )}
                  </div>
                  {currentPagare.deudores.length > 1 && (
                    <div
                      style={{
                        width: "96%",
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "10px",
                        justifyContent: "end",
                      }}
                    >
                      <button
                        style={{
                          border: "none",
                          background: "rgba(0,0,0,0.2)",
                          borderRadius: "6px",
                          color: "white",
                          textAlign: "center",
                          fontSize: "14px",
                          fontWeight: "600",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "36px",
                          padding: "0 20px 0 20px",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          setDeudoresModalOpen(true);
                        }}
                      >
                        Ver Codeudores
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div
              className="flex-col"
              style={{
                height: "85%",
                width: "54%",
                justifyContent: "start",
                padding: "1%",
              }}
            >
              <div
                className="flex-col"
                style={{
                  width: "100%",
                  height: "100%",
                  marginBottom: "2%",
                }}
              >
                <div
                  className="flex-col"
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: "29px",
                    textAlignLast: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    boxSizing: "border-box",
                    borderRadius: "8px 8px 0px 0px",
                  }}
                >
                  <div className="text-normal text-medium primary-color-35">
                    Formulario dinámico del pagaré
                  </div>
                </div>
                <div
                  style={{
                    color: "white",
                    background: "rgba(0,0,0,0.2)",
                    boxSizing: "border-box",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "90%",
                    borderRadius: "0px 0px 8px 8px ",
                  }}
                >
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr",
                      gridAutoRows: "minmax(min-content, max-content)",
                      overflow: "auto",
                      height: "100%",
                    }}
                  >
                    {values.length > 1 &&
                      currentFields.map((field, i) => {
                        return (
                          <SimpleInput
                            key={i}
                            disabled={disabledInputs}
                            label={field.label}
                            value={values[i].value}
                            placeHolder={field.placeHolder}
                            type={field.type}
                            onChange={(e) => {
                              if (field.regex && field.regex.length > 0) {
                                var reg = new RegExp(field.regex);
                                if (reg.test(e.target.value)) {
                                  let data = [...values!];
                                  data[i].value = e.target.value;
                                  setValues(data);
                                  dispatch(setValuesFields(data));
                                }
                              } else {
                                let data = [...values!];
                                data[i].value = e.target.value;
                                setValues(data);
                                dispatch(setValuesFields(data));
                              }
                            }}
                          />
                        );
                      })}
                  </div>
                  <div className="flex-col">
                    <div
                      className="flex-col"
                      style={{ visibility: sentOtp ? "visible" : "hidden" }}
                    >
                      <OtpInput
                        value={otpValue}
                        onChange={(otp: any) => {
                          setOtpValue(otp);
                        }}
                        numInputs={5}
                        inputStyle={{
                          background: "rgba(0,0,0,0.2)",
                          border: "none",
                          height: "47px",
                          width: "45px",
                          borderRadius: "6px",
                          marginRight: "7px",
                          color: "white",
                        }}
                        containerStyle={{
                          marginBottom: "8px",
                          marginLeft: "2%",
                        }}
                        isInputNum={true}
                      />
                      <div
                        style={{
                          marginLeft: "2%",
                          fontSize: "14px",
                          fontWeight: "700",
                          color: "white",
                        }}
                      >
                        Ingrese el código que llegará a tu correo electrónico
                      </div>
                    </div>
                    <div
                      className="flex-row"
                      style={{ justifyContent: "space-evenly" }}
                    >
                      <div
                        className="flex-col"
                        style={{
                          justifyContent: "end",
                          alignItems: "center",
                          visibility: showResend ? "visible" : "hidden",
                        }}
                      >
                        <div
                          style={{
                            color: "rgba(255,255,255,0.35)",
                            fontSize: "14px",
                            fontWeight: "700",
                            textAlign: "center",
                            maxWidth: "220px",
                            marginBottom: "9px",
                          }}
                        >
                          Si el código no ha llegado solicita re-eenviar el
                          código
                        </div>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch(
                              sendOtpConfirm({
                                pagareRootId: currentPagare?.id!,
                                sendAgain,
                              })
                            );
                          }}
                          style={{
                            border: "none",
                            background: "rgba(0,0,0,0.2)",
                            borderRadius: "6px",
                            color: "white",
                            textAlign: "center",
                            fontSize: "14px",
                            fontWeight: "700",
                            height: "35px",
                            width: "155px",
                          }}
                        >
                          Reenviar código
                        </button>
                      </div>
                      <div
                        className="flex-col"
                        style={{ justifyContent: "end", alignItems: "center" }}
                      >
                        <button
                          style={{
                            border: "none",
                            background: "rgba(0,0,0,0.2)",
                            borderRadius: "6px",
                            color: "white",
                            textAlign: "center",
                            fontSize: "14px",
                            fontWeight: "700",
                            height: "35px",
                            width: "155px",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            if (sentOtp) {
                              dispatch(
                                confirmOtp({
                                  code: otpValue,
                                  pagareRootId: currentPagare?.id!,
                                })
                              );
                            } else {
                              dispatch(
                                sendOtpConfirm({
                                  pagareRootId: currentPagare?.id!,
                                  sendAgain,
                                })
                              );
                              setBeginCounter(true);
                            }
                          }}
                        >
                          Confirmar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-huge text-bold primary-color">
            {loadingStatus === "rejected"
              ? "No existe el registro con id proporcionado"
              : ""}
          </h1>
        </div>
      )}
    </LoadingOverlay>
  );
};
