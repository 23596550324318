import { useEffect } from "react";
import { SidePanel } from "../components/sidePanel/SidePanel";
import { TopPanel } from "../components/TopPanel";
import { FilterComponent } from "../components/sidePanel/FilterComponent";
import { RadioButton } from "../../common/components/Btns/RadioButton";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { changeCurrentProduct, selectRoles } from "../../common/commonSlice";
import { Outlet, useNavigate } from "react-router-dom";
import {
  changeActiveComponent,
  changeCurrentFilters,
  selectActivePagareComponent,
  selectCurrentFilterPagareRequest,
  selectPagareFilterOptions,
} from "./pagareSlice";
import { Category } from "../components/sidePanel/categories/Category";
import { CategoriesComponent } from "../components/sidePanel/categories/CategoriesComponent";
import {
  changePagareFilters,
  resetStateAnalytics,
  selectAnalyticsPagareRequest,
} from "../analytics/analyticsSlice";
import { DropDown } from "../../common/components/Btns/DropDown";
import { SearchInputFilter } from "../../common/components/inputs/SearchInputFilter";
export const Pagares = () => {
  let activeComponent = useAppSelector(selectActivePagareComponent);
  let currentFilteredRequest = useAppSelector(selectCurrentFilterPagareRequest);
  let currentAnalyticsRequest = useAppSelector(selectAnalyticsPagareRequest);
  const pagareFilterOptions = useAppSelector(selectPagareFilterOptions);
  let roles = useAppSelector(selectRoles);

  let getFilterItemsByActiveComponent = (): JSX.Element | undefined => {
    if (activeComponent === "Pagares") {
      const statusOptions: { value: string; label: string }[] = [
        { label: "NO Firmado", value: "Created" },
        { label: "Firmado", value: "Signed" },
        { label: "Diligenciado", value: "Filled" },
        { label: "Cancelado", value: "Cancelled" },
        { label: "Endosado", value: "Endosado" },
        { label: "Anulado", value: "Nullified" },
        {
          label: "Bloqueado por verificación de endoso",
          value: "BlockedByEndosoValidation",
        },
      ];

      const statusDropdown = (
        <DropDown
          key={"Status"}
          title="Estado"
          options={statusOptions}
          onChange={(e) => {
            dispatch(
              changeCurrentFilters({
                ...currentFilteredRequest,
                status: e?.value!,
              })
            );
          }}
        />
      );

      const templateOptions: { value: string; label: string }[] = [];

      if (pagareFilterOptions && pagareFilterOptions.pagareTemplateOptions) {
        for (const option of pagareFilterOptions.pagareTemplateOptions) {
          templateOptions.push({ label: option.name, value: option.id });
        }
      }

      const templateDropdown = (
        <DropDown
          key={"Template"}
          title="Plantilla"
          options={templateOptions}
          onChange={(e) => {
            dispatch(
              changeCurrentFilters({
                ...currentFilteredRequest,
                template: e?.value!,
              })
            );
          }}
        />
      );

      const searchInputDocument = (
        <SearchInputFilter
          value={currentFilteredRequest.userIdentification}
          key={"SearchByDocument"}
          onChange={(e) => {
            if (!e.target.value || e.target.value === "") {
              dispatch(
                changeCurrentFilters({
                  ...currentFilteredRequest,
                  userIdentification: null,
                })
              );
            } else {
              dispatch(
                changeCurrentFilters({
                  ...currentFilteredRequest,
                  userIdentification: e.target.value,
                  noPagare: null,
                })
              );
            }
          }}
          style={{ marginTop: "5px", width: "90%", alignSelf: "center" }}
          placeHolder="No documento Deudor"
        />
      );

      const searchInputNumeroPagare = (
        <SearchInputFilter
          value={currentFilteredRequest.noPagare}
          key={"SearchByPagareNumber"}
          onChange={(e) => {
            if (!e.target.value || e.target.value === "") {
              dispatch(
                changeCurrentFilters({
                  ...currentFilteredRequest,
                  noPagare: null,
                  userIdentification: null,
                })
              );
            } else {
              dispatch(
                changeCurrentFilters({
                  ...currentFilteredRequest,
                  noPagare: e.target.value,
                  userIdentification: null,
                })
              );
            }
          }}
          style={{ marginTop: "5px", width: "90%", alignSelf: "center" }}
          placeHolder="Número de Pagare"
        />
      );

      return (
        <FilterComponent
          key={"PagaresFilterComponent"}
          hasCalendar={true}
          endDate={
            currentFilteredRequest.endDate
              ? new Date(currentFilteredRequest.endDate)
              : null
          }
          startDate={
            currentFilteredRequest.startDate
              ? new Date(currentFilteredRequest.startDate)
              : null
          }
          setEndDate={(date: Date) => {
            if (date) {
              if (date) {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    endDate: date.toISOString(),
                  })
                );
              }
            }
          }}
          setStartDate={(date: Date) => {
            if (date) {
              if (date) {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    startDate: date.toISOString(),
                  })
                );
              }
            }
          }}
          resetFunction={() => {
            dispatch(
              changeCurrentFilters({
                ...currentFilteredRequest,
                startDate: null,
                endDate: null,
              })
            );
          }}
          items={[
            statusDropdown,
            <div key={4} style={{ alignItems: "center" }}></div>,
            templateDropdown,
            <div
              key={"Por Identificación"}
              className="text-normal text-small primary-color"
              style={{
                alignSelf: "start",
                marginTop: "10px",
                marginBottom: "5px",
              }}
            >
              Por identificación
            </div>,
            searchInputDocument,
            <div
              key={"Por Numero de Pagare"}
              className="text-normal text-small primary-color"
              style={{
                alignSelf: "start",
                marginTop: "10px",
                marginBottom: "5px",
              }}
            >
              Por numero de pagaré
            </div>,
            searchInputNumeroPagare,
          ]}
        />
      );
    } else if (activeComponent === "Analytics") {
      return (
        <FilterComponent
          key={0}
          hasCalendar={false}
          calendarType={"double"}
          endDate={
            currentAnalyticsRequest.endDate
              ? new Date(currentAnalyticsRequest.endDate)
              : null
          }
          startDate={
            currentAnalyticsRequest.startDate
              ? new Date(currentAnalyticsRequest.startDate)
              : null
          }
          setEndDate={(date: Date) => {
            if (date) {
              if (date) {
                dispatch(
                  changePagareFilters({
                    ...currentAnalyticsRequest,
                    endDate: date.toISOString(),
                  })
                );
              }
            }
          }}
          setStartDate={(date: Date) => {
            if (date) {
              if (date) {
                dispatch(
                  changePagareFilters({
                    ...currentAnalyticsRequest,
                    startDate: date.toISOString(),
                  })
                );
              }
            }
          }}
          items={[
            <div
              key={1}
              className="text-normal text-small primary-color"
              style={{
                alignSelf: "start",
                marginTop: "10px",
                marginBottom: "5px",
              }}
            >
              Tipo de Pagaré
            </div>,
            <div key={2} style={{ alignItems: "center" }}>
              <RadioButton
                label="Blanco con carta"
                checked={currentAnalyticsRequest.type === "Blank"}
                onClick={() => {
                  if (currentAnalyticsRequest.type === "Blank") {
                    dispatch(
                      changePagareFilters({
                        ...currentAnalyticsRequest,
                        type: null,
                      })
                    );
                  }
                }}
                onChange={() => {
                  dispatch(
                    changePagareFilters({
                      ...currentAnalyticsRequest,
                      type: "Blank",
                    })
                  );
                }}
              />
              <RadioButton
                label="Cerrado"
                checked={currentAnalyticsRequest.type === "Closed"}
                onClick={() => {
                  if (currentAnalyticsRequest.type === "Closed") {
                    dispatch(
                      changePagareFilters({
                        ...currentAnalyticsRequest,
                        type: null,
                      })
                    );
                  }
                }}
                onChange={() => {
                  dispatch(
                    changePagareFilters({
                      ...currentAnalyticsRequest,
                      type: "FilledOut",
                    })
                  );
                }}
              />
            </div>,
            <div
              key={3}
              className="text-normal text-small primary-color"
              style={{
                alignSelf: "start",
                marginTop: "10px",
                marginBottom: "5px",
              }}
            >
              Estado
            </div>,
            <div key={4} style={{ alignItems: "center" }}>
              <RadioButton
                label="Creado"
                checked={currentAnalyticsRequest.status === "Created"}
                onClick={() => {
                  if (currentAnalyticsRequest.status === "Created") {
                    dispatch(
                      changePagareFilters({
                        ...currentAnalyticsRequest,
                        status: null,
                      })
                    );
                  }
                }}
                onChange={() => {
                  dispatch(
                    changePagareFilters({
                      ...currentAnalyticsRequest,
                      status: "Created",
                    })
                  );
                }}
              />
              <RadioButton
                label="Firmado"
                checked={currentAnalyticsRequest.status === "Signed"}
                onClick={() => {
                  if (currentAnalyticsRequest.status === "Signed") {
                    dispatch(
                      changePagareFilters({
                        ...currentAnalyticsRequest,
                        status: null,
                      })
                    );
                  }
                }}
                onChange={() => {
                  dispatch(
                    changePagareFilters({
                      ...currentAnalyticsRequest,
                      status: "Signed",
                    })
                  );
                }}
              />
              <RadioButton
                label="Diligenciado"
                checked={currentAnalyticsRequest.status === "Filled"}
                onClick={() => {
                  if (currentAnalyticsRequest.status === "Filled") {
                    dispatch(
                      changePagareFilters({
                        ...currentAnalyticsRequest,
                        status: null,
                      })
                    );
                  }
                }}
                onChange={() => {
                  dispatch(
                    changePagareFilters({
                      ...currentAnalyticsRequest,
                      status: "Filled",
                    })
                  );
                }}
              />
              <RadioButton
                label="Endosado"
                checked={currentAnalyticsRequest.status === "Endosado"}
                onClick={() => {
                  if (currentAnalyticsRequest.status === "Endosado") {
                    dispatch(
                      changePagareFilters({
                        ...currentAnalyticsRequest,
                        status: null,
                      })
                    );
                  }
                }}
                onChange={() => {
                  dispatch(
                    changePagareFilters({
                      ...currentAnalyticsRequest,
                      status: "Endosado",
                    })
                  );
                }}
              />
            </div>,
          ]}
        />
      );
    } else {
      return;
    }
  };

  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  const getCategoryItems = (): JSX.Element[] => {
    let items: JSX.Element[] = [];
    items.push(
      <Category
        key={0}
        name="Pagarés creados"
        last
        selected={activeComponent === "Pagares"}
        onClick={(e) => {
          e.preventDefault();
          navigate("/services/pagares/list");
        }}
      />
    );
    if (
      roles.includes("analytics.admin") ||
      roles.includes("analytics.pagares.view")
    ) {
      items.push(
        <Category
          key={1}
          name="Analítica"
          last
          selected={activeComponent === "Analytics"}
          onClick={(e) => {
            e.preventDefault();
            dispatch(changeCurrentProduct("Pagarés"));
            dispatch(changeActiveComponent("Analytics"));
            dispatch(resetStateAnalytics());
            navigate("/services/pagares/analytics");
          }}
        />
      );
    }

    return items;
  };

  useEffect(() => {
    dispatch(changeCurrentProduct("Pagarés"));
    dispatch(changeActiveComponent("Pagares"));
  }, []);
  return (
    <div
      className="flex-col"
      style={{
        paddingLeft: "0.5%",
        paddingTop: "0.5%",
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      <TopPanel />
      <div
        className="flex-row"
        style={{ justifyContent: "start", height: "95%" }}
      >
        <SidePanel
          filter={getFilterItemsByActiveComponent()}
          categories={<CategoriesComponent categories={getCategoryItems()} />}
        />

        <div
          className="flex-col"
          style={{ width: "80%", justifyContent: "end" }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};
