import "bootstrap/dist/css/bootstrap.min.css";
import { ReactComponent as LeftArrow } from "../../../../Assets/common/images/leftArrow.svg";
import { ReactComponent as Carga } from "../../../../Assets/common/images/carga.svg";
import LoadingOverlay from "react-loading-overlay-ts";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useNavigate, useParams } from "react-router-dom";
import {
  changeActiveComponent,
  generateUrl,
  getPagareOptions,
  resetCreatePagare,
  selectActivePagareComponent,
  selectCreatePagareOptions,
  selectCurrentPagareCreate,
  selectLoadingCreatePagare,
  selectLoadingPagareOptions,
  updatePagareCreate,
} from "../pagareSlice";
import ClipLoader from "react-spinners/ClipLoader";
import Modal, { Styles } from "react-modal";
import { DropDownPagare } from "../../../common/components/Btns/DropDown";
import { arrayBufferToBase64 } from "../utils";
import isEmail from "validator/lib/isEmail";
import { v4 as uuidv4 } from "uuid";

const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "60%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    width: "30%",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "1%",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};
const customStylesModalConfirmation: Styles = {
  content: {
    top: "50%",
    left: "55%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    width: "50%",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "15px",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};
Modal.setAppElement("#root");

export const PagareCreate = () => {
  let activeComponent = useAppSelector(selectActivePagareComponent);
  let pagareCreateOptions = useAppSelector(selectCreatePagareOptions);
  let loadingOptions = useAppSelector(selectLoadingPagareOptions);
  let loadingCreate = useAppSelector(selectLoadingCreatePagare);
  let createPagareRequest = useAppSelector(selectCurrentPagareCreate);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalConfirmationOpen, setModalConfirmationOpen] = useState(false);
  const [modalOpenCreateResult, setModalOpenCreateResult] = useState(false);

  let navigate = useNavigate();
  let dispatch = useAppDispatch();

  let { id } = useParams();

  const canCreatePagare = () => {
    const deudores = createPagareRequest.deudores;
    if (deudores.length > 0) {
      for (let i = 0; i < deudores.length; i++) {
        if (i === 0) {
          if (!deudores[i].name || !deudores[i].email) {
            return false;
          }
        }
        if (deudores[i].errorMessageName || deudores[i].errorMessageEmail) {
          return false;
        }
      }
      return (
        createPagareRequest.pagareTemplateId &&
        createPagareRequest.projectId &&
        loadingCreate !== "pending"
      );
    } else {
      return false;
    }
  };

  const docInfoRender = () => {
    if (
      createPagareRequest.documents &&
      createPagareRequest.documents.length > 0
    ) {
      return (
        <div className="flex-row" style={{ width: "100%" }}>
          {createPagareRequest.documents.map((doc, i) => {
            return (
              <div
                key={`doc-${i}`}
                style={{
                  margin: "0px 10px 0 0",
                  padding: "8px",
                  background: "rgba(0,0,0,0.2)",
                  borderRadius: "8px",
                  maxWidth: "150px",
                }}
              >
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "white",
                    margin: "0",
                  }}
                >
                  {doc.documentName}
                </p>
                <button
                  style={{
                    fontSize: "12px",
                    background: "rgba(0,0,0,0.2)",
                    borderRadius: "8px",
                    padding: "5px",
                    fontWeight: "600",
                    color: "white",
                    marginTop: "10px",
                    border: "none",
                  }}
                  onClick={() => {
                    const newDocs = createPagareRequest.documents.filter(
                      (x) => x.documentId != doc.documentId
                    );
                    dispatch(
                      updatePagareCreate({
                        ...createPagareRequest,
                        documents: newDocs,
                      })
                    );
                  }}
                >
                  Eliminar documento
                </button>
              </div>
            );
          })}
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const showPrefillData = () => {
    const currentTemplate = pagareCreateOptions?.pagareTemplateOptions?.find(
      (x) => x.id == createPagareRequest.pagareTemplateId
    );
    if (currentTemplate && currentTemplate.prefillFields.length > 0) {
      const prefillFieldsFirstColumn: JSX.Element[] = [];
      const prefillFieldsSecondColumn: JSX.Element[] = [];
      const halfEntries = Object.entries(currentTemplate.prefillFields).slice(
        0,
        Math.ceil(currentTemplate.prefillFields.length / 2)
      );
      const otherHalfEntries = Object.entries(
        currentTemplate.prefillFields
      ).slice(Math.ceil(currentTemplate.prefillFields.length / 2));
      halfEntries.map(([key, value]) => {
        const fieldValue = createPagareRequest.prefillInfo[key];
        prefillFieldsFirstColumn.push(
          <div className="flex-col" style={{ width: "100%" }} key={key}>
            <div
              style={{
                margin: "0px",
                padding: "0px 5% 0 0",
                width: "100%",
              }}
            >
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: "600",
                  color: "white",
                  margin: "5px 0px 0px 5px",
                }}
              >
                {value.label || value.key}
              </div>
              <div style={{ marginTop: "6px", marginLeft: "2px" }}>
                <input
                  value={fieldValue}
                  placeholder={value.label || value.key}
                  disabled={false}
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "rgba(255,255,255,0.8)",
                    height: "35px",
                    background: "rgba(255,255,255,0.15)",
                    borderRadius: "6px",
                    border: "none",
                    paddingLeft: "10px",
                    width: "100%",
                  }}
                  onChange={(e) => {
                    dispatch(
                      updatePagareCreate({
                        ...createPagareRequest,
                        prefillInfo: {
                          ...createPagareRequest.prefillInfo,
                          [value.key]: e.target.value,
                        },
                      })
                    );
                  }}
                />
              </div>
            </div>
          </div>
        );
      });
      otherHalfEntries.map(([key, value]) => {
        const fieldValue = createPagareRequest.prefillInfo[key];
        prefillFieldsSecondColumn.push(
          <div className="flex-col" style={{ width: "100%" }} key={key}>
            <div
              style={{
                margin: "0px",
                padding: "0px 5% 0 0",
                width: "100%",
              }}
            >
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: "600",
                  color: "white",
                  margin: "5px 0px 0px 5px",
                }}
              >
                {value.label || value.key}
              </div>
              <div style={{ marginTop: "6px", marginLeft: "2px" }}>
                <input
                  value={fieldValue}
                  placeholder={value.label || value.key}
                  disabled={false}
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "rgba(255,255,255,0.8)",
                    height: "35px",
                    background: "rgba(255,255,255,0.15)",
                    borderRadius: "6px",
                    border: "none",
                    paddingLeft: "10px",
                    width: "100%",
                  }}
                  onChange={(e) => {
                    dispatch(
                      updatePagareCreate({
                        ...createPagareRequest,
                        prefillInfo: {
                          ...createPagareRequest.prefillInfo,
                          [key]: e.target.value,
                        },
                      })
                    );
                  }}
                />
              </div>
            </div>
          </div>
        );
      });
      return (
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,0.2)",
            borderRadius: "8px",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "30px",
              backgroundColor: "rgba(0,0,0,0.2)",
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            <h6
              style={{
                color: "rgba(255,255,255,0.6)",
                textAlign: "center",
                margin: "0px",
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              Prellenado de pagaré
            </h6>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "0px 0px 8px 8px",
              padding: "12px",
            }}
          >
            <h6
              style={{
                color: "white",
                textAlign: "start",
                fontSize: "12px",
                fontWeight: "600",
                margin: "10px 0 10px 10px",
              }}
            >
              A continuación se ve la información que se debe llenar en el
              pagaré antes de ser firmado.
            </h6>

            <div
              className="flex-row"
              style={{ width: "100%", maxHeight: "230px", overflow: "auto" }}
            >
              <div className="flex-col" style={{ width: "50%" }}>
                {prefillFieldsFirstColumn}
              </div>
              <div className="flex-col" style={{ width: "50%" }}>
                {prefillFieldsSecondColumn}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const deudoresInfoRender = () => {
    const currentTemplate = pagareCreateOptions?.pagareTemplateOptions?.find(
      (x) => x.id == createPagareRequest.pagareTemplateId
    );
    const deudoresInfoItems: JSX.Element[] = [];
    if (currentTemplate) {
      for (let i = 0; i < currentTemplate.maxDeudores; i++) {
        const deudorInfo = createPagareRequest.deudores
          ? createPagareRequest.deudores[i]
          : null;
        deudoresInfoItems.push(
          <div className="flex-col" style={{ width: "100%" }}>
            <h6
              style={{
                color: "white",
                textAlign: "start",
                fontSize: "15px",
                fontWeight: "700",
                margin: "10px 0 5px 5px",
              }}
            >
              Deudor {i + 1}
            </h6>
            <div className="flex-row" style={{ width: "100%" }}>
              <div className="flex-col" style={{ width: "50%" }}>
                <div
                  style={{
                    margin: "0px",
                    padding: "0px 5% 0 0",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "white",
                      margin: "5px 0px 0px 5px",
                    }}
                  >
                    Nombres
                  </div>
                  <div style={{ marginTop: "6px", marginLeft: "2px" }}>
                    <input
                      value={deudorInfo?.name}
                      placeholder={"Nombre deudor"}
                      disabled={false}
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "rgba(255,255,255,0.8)",
                        height: "35px",
                        background: "rgba(255,255,255,0.15)",
                        borderRadius: "6px",
                        border: "none",
                        paddingLeft: "10px",
                        width: "100%",
                      }}
                      onChange={(e) => {
                        if (e.target.value) {
                          dispatch(
                            updatePagareCreate({
                              ...createPagareRequest,
                              deudores: [
                                ...createPagareRequest.deudores.slice(0, i),
                                deudorInfo
                                  ? {
                                      ...deudorInfo,
                                      name: e.target.value,
                                      errorMessageName: "",
                                    }
                                  : {
                                      name: e.target.value,
                                      errorMessageName: "",
                                      errorMessageEmail: "",
                                      documentIdsToSign: [],
                                      email: "",
                                      position: i + 1,
                                    },
                                ...createPagareRequest.deudores.slice(i + 1),
                              ],
                            })
                          );
                        } else {
                          dispatch(
                            updatePagareCreate({
                              ...createPagareRequest,
                              deudores: [
                                ...createPagareRequest.deudores.slice(0, i),
                                deudorInfo
                                  ? {
                                      ...deudorInfo,
                                      name: e.target.value,
                                      errorMessageName: "Ingrese un nombre",
                                    }
                                  : {
                                      name: e.target.value,
                                      errorMessageName: "Ingrese un nombre",
                                      errorMessageEmail: "",
                                      documentIdsToSign: [],
                                      email: "",
                                      position: i + 1,
                                    },
                                ...createPagareRequest.deudores.slice(i + 1),
                              ],
                            })
                          );
                        }
                      }}
                    />
                  </div>
                  <p
                    style={{
                      fontSize: "10px",
                      color: "rgba(255, 105, 105, 0.7)",
                      fontWeight: "400",
                      textAlign: "end",
                      margin: "2px 2px 0 0",
                      visibility: deudorInfo?.errorMessageName
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    {deudorInfo?.errorMessageName}
                  </p>
                </div>
              </div>
              <div className="flex-col" style={{ width: "50%" }}>
                <div
                  style={{
                    margin: "0px",
                    padding: "0px 5% 0 0",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "white",
                      margin: "5px 0px 0px 5px",
                    }}
                  >
                    Correo electrónico
                  </div>
                  <div style={{ marginTop: "6px", marginLeft: "2px" }}>
                    <input
                      value={deudorInfo?.email}
                      placeholder={"Correo deudor"}
                      disabled={false}
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "rgba(255,255,255,0.8)",
                        height: "35px",
                        background: "rgba(255,255,255,0.15)",
                        borderRadius: "6px",
                        border: "none",
                        paddingLeft: "10px",
                        width: "100%",
                      }}
                      onChange={(e) => {
                        if (e.target.value && isEmail(e.target.value)) {
                          dispatch(
                            updatePagareCreate({
                              ...createPagareRequest,
                              deudores: [
                                ...createPagareRequest.deudores.slice(0, i),
                                deudorInfo
                                  ? {
                                      ...deudorInfo,
                                      email: e.target.value.toLowerCase(),
                                      errorMessageEmail: "",
                                    }
                                  : {
                                      email: e.target.value.toLowerCase(),
                                      errorMessageName: "",
                                      errorMessageEmail: "",
                                      documentIdsToSign: [],
                                      name: "",
                                      position: i + 1,
                                    },
                                ...createPagareRequest.deudores.slice(i + 1),
                              ],
                            })
                          );
                        } else {
                          dispatch(
                            updatePagareCreate({
                              ...createPagareRequest,
                              deudores: [
                                ...createPagareRequest.deudores.slice(0, i),
                                deudorInfo
                                  ? {
                                      ...deudorInfo,
                                      email: e.target.value
                                        ? e.target.value.toLowerCase()
                                        : "",
                                      errorMessageEmail:
                                        "Ingrese un correo válido",
                                    }
                                  : {
                                      email: e.target.value
                                        ? e.target.value.toLowerCase()
                                        : "",
                                      errorMessageName:
                                        "Ingrese un  correo válido",
                                      errorMessageEmail: "",
                                      documentIdsToSign: [],
                                      name: "",
                                      position: i + 1,
                                    },
                                ...createPagareRequest.deudores.slice(i + 1),
                              ],
                            })
                          );
                        }
                      }}
                    />
                  </div>
                  <p
                    style={{
                      fontSize: "10px",
                      color: "rgba(255, 105, 105, 0.7)",
                      fontWeight: "400",
                      textAlign: "end",
                      margin: "2px 2px 0 0",
                      visibility: deudorInfo?.errorMessageEmail
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    {deudorInfo?.errorMessageEmail}
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      }
      return (
        <div className="flex-col" style={{ width: "100%" }}>
          <h6
            style={{
              color: "white",
              textAlign: "start",
              fontSize: "12px",
              fontWeight: "600",
              margin: "5px 0 10px 10px",
            }}
          >
            Agregue a los deudores según la configuración de la plantilla
            seleccionada.
            <br /> Para esta plantilla el máximo de deudores es:{" "}
            {currentTemplate.maxDeudores}
          </h6>
          {deudoresInfoItems}
        </div>
      );
    } else {
      return (
        <h6
          style={{
            color: "white",
            textAlign: "start",
            fontSize: "12px",
            fontWeight: "600",
            margin: "10px 0 10px 10px",
          }}
        >
          Seleccione primero la plantilla de pagaré.
        </h6>
      );
    }
  };

  const emailToSendShow = () => {
    const emails = createPagareRequest.deudores.map((deudor) => deudor.email);
    return (
      <span style={{ fontSize: "12px", color: "black", fontWeight: "bolder" }}>
        {emails.join(", ")}
      </span>
    );
  };

  useEffect(() => {
    if (!pagareCreateOptions && loadingOptions === "idle") {
      dispatch(getPagareOptions());
    }

    if (activeComponent == "none") {
      dispatch(changeActiveComponent("none"));
    }
  }, [id]);

  useEffect(() => {
    if (loadingCreate === "rejected" || loadingCreate === "resolved") {
      setModalOpenCreateResult(true);
      setModalConfirmationOpen(false);
    }
  }, [loadingCreate]);

  return (
    <LoadingOverlay
      active={loadingOptions === "pending"}
      spinner={<ClipLoader color="white" size="100px" />}
    >
      <Modal
        isOpen={modalOpenCreateResult}
        onRequestClose={() => {
          setModalIsOpen(false);
          if (loadingCreate === "resolved") {
            dispatch(resetCreatePagare());
            navigate(-1);
          }
        }}
        style={customStylesModal}
        contentLabel="Resultado Creación Pagaré"
        shouldCloseOnOverlayClick={true}
      >
        <div
          className="flex-col"
          style={{
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div
            className="text-medium text-normal"
            style={{
              color: "black",
              marginBottom: "10px",
              paddingLeft: "10%",
              paddingRight: "10%",
            }}
          >
            {loadingCreate === "resolved"
              ? "Pagaré creado con exito"
              : "Error en creación de pagaré"}
          </div>
          {loadingCreate === "resolved" ? (
            <p
              style={{
                color: "black",
                marginBottom: "10px",
                fontSize: "12px",
                fontWeight: "500",
                textAlign: "center",
                width: "80%",
              }}
            >
              Se ha enviado a los siguientes correos: {emailToSendShow()} un
              enlace para realizar la firma del pagaré y otros documentos
              seleccionados.
              <br />
            </p>
          ) : (
            <p
              style={{
                color: "black",
                marginBottom: "10px",
                fontSize: "12px",
                fontWeight: "500",
                textAlign: "center",
                width: "80%",
              }}
            >
              Hubo un problema creando el pagaré y enviando los enlaces de firma
              a los deudores, por favor intente de nuevo más tarde
            </p>
          )}
          <button
            className="text-medium text-bold primary-color"
            style={{
              border: "none",
              textAlign: "center",
              borderRadius: "8px",
              background: "#1A617D",
              paddingTop: "5px",
              paddingBottom: "5px",
              paddingRight: "10%",
              paddingLeft: "10%",
            }}
            onClick={(e) => {
              e.preventDefault();
              setModalIsOpen(false);
              if (loadingCreate === "resolved") {
                dispatch(resetCreatePagare());
                navigate(-1);
              }
            }}
          >
            {loadingCreate === "resolved" ? "Finalizar" : "Intentar de nuevo"}
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setModalIsOpen(false);
        }}
        style={customStylesModal}
        contentLabel="Error de búsqueda"
        shouldCloseOnOverlayClick={true}
      >
        <div
          className="flex-col"
          style={{
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div
            className="text-medium text-normal"
            style={{
              color: "black",
              marginBottom: "10px",
              paddingLeft: "10%",
              paddingRight: "10%",
            }}
          >
            Tipo de archivo inválido
          </div>
          <div
            className="text-medium text-normal"
            style={{
              color: "black",
              marginBottom: "10px",
              paddingLeft: "10%",
              paddingRight: "10%",
            }}
          >
            Recuerda que para que pueda ser firmado el archivo debe ser de
            formato PDF
          </div>
          <button
            className="text-medium text-bold primary-color"
            style={{
              border: "none",
              textAlign: "center",
              borderRadius: "8px",
              background: "#1A617D",
              paddingTop: "5px",
              paddingBottom: "5px",
              paddingRight: "10%",
              paddingLeft: "10%",
            }}
            onClick={(e) => {
              e.preventDefault();
              setModalIsOpen(false);
            }}
          >
            Cerrar
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={modalConfirmationOpen}
        onRequestClose={() => {
          setModalConfirmationOpen(false);
        }}
        style={customStylesModalConfirmation}
        contentLabel="Confirmar Creación de Pagaré"
        shouldCloseOnOverlayClick={false}
      >
        {loadingCreate === "pending" ? (
          <div
            className="flex-col"
            style={{
              height: "100%",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ClipLoader color="black" size={"100px"} />
          </div>
        ) : (
          <div
            className="flex-col"
            style={{
              height: "100%",
              width: "100%",
              alignItems: "center",
            }}
          >
            <h4
              className="text-medium text-normal"
              style={{
                color: "black",
                marginBottom: "10px",
                textAlign: "center",
                fontSize: "15px",
              }}
            >
              Confirmar creación
            </h4>
            <p
              style={{
                color: "black",
                marginBottom: "10px",
                fontSize: "12px",
                fontWeight: "500",
                textAlign: "center",
                width: "80%",
              }}
            >
              Se enviarán a los siguientes correos {emailToSendShow()} un enlace
              para realizar la firma del pagaré y otros documentos
              seleccionados.
              <br />
              Antes de finalizar, ingresa el asunto y un detalle del correo que
              llegará a los deudores con el enlace de la firma. Esto es
              opcional, pero ayudará a los deudores a entender el correo.
              <br />
            </p>
            <div
              style={{
                margin: "0px",
                padding: "0",
                width: "65%",
              }}
            >
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: "600",
                  color: "black",
                  margin: "5px 0px 0px 5px",
                }}
              >
                Asunto del correo
              </div>
              <div style={{ marginTop: "6px", marginLeft: "2px" }}>
                <input
                  value={createPagareRequest.emailSubject}
                  placeholder={"Opcional"}
                  disabled={false}
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    color: "rgba(0,0,0,0.8)",
                    height: "35px",
                    background: "transparent",
                    borderRadius: "6px",
                    border: "1px solid rgba(0,0,0,0.4)",
                    paddingLeft: "10px",
                    width: "100%",
                  }}
                  onChange={(e) => {
                    dispatch(
                      updatePagareCreate({
                        ...createPagareRequest,
                        emailSubject: e.target.value,
                      })
                    );
                  }}
                />
              </div>
            </div>
            <div
              style={{
                marginBottom: "40px",
                padding: "0",
                width: "65%",
              }}
            >
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: "600",
                  color: "black",
                  margin: "10px 0px 0px 5px",
                }}
              >
                Contenido del correo
              </div>
              <div style={{ marginTop: "6px", marginLeft: "2px" }}>
                <input
                  value={createPagareRequest.emailDescription}
                  placeholder={"Opcional"}
                  disabled={false}
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    color: "rgba(0,0,0,0.8)",
                    height: "35px",
                    background: "transparent",
                    borderRadius: "6px",
                    paddingLeft: "10px",
                    border: "1px solid rgba(0,0,0,0.4)",
                    width: "100%",
                  }}
                  onChange={(e) => {
                    dispatch(
                      updatePagareCreate({
                        ...createPagareRequest,
                        emailDescription: e.target.value,
                      })
                    );
                  }}
                />
              </div>
            </div>
            <div
              className="flex-row"
              style={{
                width: "80%",
                justifyContent: "space-evenly",
                justifySelf: "end",
                alignItems: "center",
              }}
            >
              {" "}
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setModalConfirmationOpen(false);
                }}
                className="flex-col text-medium text-bold"
                style={{
                  background: "transparent",
                  width: "35%",
                  height: "30px",
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "8px",
                  border: "1px solid #1A617D",
                  color: "#1A617D",
                }}
              >
                Cerrar ventana
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(generateUrl());
                }}
                className="flex-col text-medium text-bold primary-color"
                style={{
                  background: "#1A617D",
                  width: "35%",
                  height: "30px",
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "8px",
                  border: "none",
                }}
              >
                Crear Pagaré
              </button>
            </div>
          </div>
        )}
      </Modal>
      <div
        className="flex-col"
        style={{ height: "100%", overflow: "auto", width: "100%" }}
      >
        <div
          className="flex-row"
          style={{
            height: "5%",
            justifyContent: "end",
            alignItems: "center",
            marginRight: "5%",
          }}
        >
          <div
            onClick={(e) => {
              e.preventDefault();
              dispatch(resetCreatePagare());
              navigate(-1);
            }}
            className="flex-row"
            style={{
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <LeftArrow style={{ marginRight: "15px" }} />
            <div className="text-bold text-xx-large primary-color">
              Regresar a la lista de pagarés
            </div>
          </div>
        </div>
        <div className="flex-row" style={{ width: "100%" }}>
          <div
            className="flex-col"
            style={{ alignItems: "center", padding: "1%", width: "50%" }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "rgba(0,0,0,0.2)",
                borderRadius: "8px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "30px",
                  backgroundColor: "rgba(0,0,0,0.2)",
                  borderRadius: "8px 8px 0px 0px",
                }}
              >
                <h6
                  style={{
                    color: "rgba(255,255,255,0.6)",
                    textAlign: "center",
                    margin: "0px",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  Configuración de pagaré
                </h6>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "0px 0px 8px 8px",
                  padding: "12px",
                }}
              >
                <h6
                  style={{
                    color: "white",
                    textAlign: "start",
                    fontSize: "12px",
                    fontWeight: "600",
                    margin: "10px 0 10px 10px",
                  }}
                >
                  A continuación seleccione la plantilla sobre la cual va a
                  crear el pagaré
                  <br />
                  {pagareCreateOptions &&
                    pagareCreateOptions.projectOptions &&
                    pagareCreateOptions.projectOptions.length > 0 && (
                      <span style={{ color: "white", fontWeight: "600" }}>
                        Tambien puede seleccionar el proyecto de firma, que
                        tiene las configuraciones del proceso de realización de
                        firma.
                      </span>
                    )}
                </h6>
                {pagareCreateOptions ? (
                  <div
                    className="flex-row"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "80%",
                    }}
                  >
                    {pagareCreateOptions.pagareTemplateOptions &&
                      pagareCreateOptions.pagareTemplateOptions.length > 0 && (
                        <DropDownPagare
                          maxContent
                          title="Plantilla"
                          options={pagareCreateOptions.pagareTemplateOptions.map(
                            (x) => ({ label: x.name, value: x.id })
                          )}
                          defaultValue={{
                            label:
                              pagareCreateOptions.pagareTemplateOptions[0].name,
                            value:
                              pagareCreateOptions.pagareTemplateOptions[0].id,
                          }}
                          onChange={(e) => {
                            dispatch(
                              updatePagareCreate({
                                ...createPagareRequest,
                                pagareTemplateId: e?.value || "",
                              })
                            );
                          }}
                        />
                      )}
                    {pagareCreateOptions.projectOptions &&
                      pagareCreateOptions.projectOptions.length > 0 && (
                        <DropDownPagare
                          maxContent
                          title="Proyecto de firma"
                          options={pagareCreateOptions.projectOptions.map(
                            (x) => ({ label: x.name, value: x.id })
                          )}
                          defaultValue={{
                            label: pagareCreateOptions.projectOptions[0].name,
                            value: pagareCreateOptions.projectOptions[0].id,
                          }}
                          onChange={(e) => {
                            dispatch(
                              updatePagareCreate({
                                ...createPagareRequest,
                                projectId: e?.value || "",
                              })
                            );
                          }}
                        />
                      )}
                  </div>
                ) : (
                  <div className="flex-col" style={{ alignItems: "center" }}>
                    <h6
                      style={{
                        color: "white",
                        textAlign: "center",
                        fontSize: "11px",
                        fontWeight: "600",
                        margin: "0px",
                      }}
                    >
                      Ha ocurrido un error obteniendo la información de las
                      configuraciones del pagaré.
                    </h6>
                    <button
                      style={{
                        background: "rgba(0,0,0,0.4)",
                        color: "white",
                        border: "none",
                        borderRadius: "8px",
                        height: "25px",
                        padding: "0px 20px",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "10px",
                        marginTop: "10px",
                      }}
                      onClick={() => {
                        dispatch(getPagareOptions());
                      }}
                    >
                      <h6
                        style={{
                          margin: "0px",
                          fontSize: "14px",
                          fontWeight: "600",
                          textAlign: "center",
                        }}
                      >
                        {" "}
                        Intentar de nuevo
                      </h6>
                    </button>
                  </div>
                )}
              </div>
            </div>
            {showPrefillData()}
          </div>
          <div
            className="flex-col"
            style={{ alignItems: "center", padding: "1%", width: "50%" }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "rgba(0,0,0,0.2)",
                borderRadius: "8px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "30px",
                  backgroundColor: "rgba(0,0,0,0.2)",
                  borderRadius: "8px 8px 0px 0px",
                }}
              >
                <h6
                  style={{
                    color: "rgba(255,255,255,0.6)",
                    textAlign: "center",
                    margin: "0px",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  Configuración de Deudores
                </h6>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  maxHeight: "290px",
                  overflow: "auto",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "center",
                  borderRadius: "0px 0px 8px 8px",
                  padding: "12px",
                }}
              >
                {deudoresInfoRender()}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "rgba(0,0,0,0.2)",
                borderRadius: "8px",
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "30px",
                  backgroundColor: "rgba(0,0,0,0.2)",
                  borderRadius: "8px 8px 0px 0px",
                }}
              >
                <h6
                  style={{
                    color: "rgba(255,255,255,0.6)",
                    textAlign: "center",
                    margin: "0px",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  Agregár más documentos
                </h6>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "0px 0px 8px 8px",
                  padding: "12px",
                }}
              >
                <h6
                  style={{
                    color: "white",
                    textAlign: "start",
                    fontSize: "12px",
                    fontWeight: "600",
                    margin: "5px 0 10px 10px",
                  }}
                >
                  Si desea agregar más documentos para ser firmados junto con el
                  pagaré, los puede agregar aquí (Todos los deudores
                  seleccionados deben firmarlo)
                </h6>
                {docInfoRender()}
                {createPagareRequest.documents &&
                  createPagareRequest.documents.length < 5 && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "end",
                        marginBottom: "1%",
                        marginTop: "1%",
                      }}
                    >
                      <label
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          cursor: "pointer",
                          marginRight: "2%",
                        }}
                        htmlFor="fileInput"
                      >
                        <input
                          style={{
                            display: "none",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onChange={async (e) => {
                            let file = e.target.files
                              ? e.target.files[0]
                              : null;
                            let name = "";
                            let type = "";
                            let fileBase64 = "";
                            if (file) {
                              name = file.name;
                              type = file.type;
                              let temp = await file.arrayBuffer();
                              fileBase64 = arrayBufferToBase64(temp);
                            }
                            if (type === "application/pdf") {
                              dispatch(
                                updatePagareCreate({
                                  ...createPagareRequest,
                                  documents: [
                                    ...createPagareRequest.documents,
                                    {
                                      documentBase64: fileBase64,
                                      documentName: name,
                                      documentId: uuidv4(),
                                      signable: true,
                                    },
                                  ],
                                })
                              );
                            } else {
                              setModalIsOpen(true);
                            }
                          }}
                          type="file"
                          id="fileInput"
                        />

                        <Carga style={{ width: "40px", height: "40px" }} />
                        <div
                          style={{
                            fontSize: "15px",
                            lineHeight: "16px",
                            fontWeight: "500",
                            color: "white",
                            margin: "8px",
                          }}
                        >
                          Cargar archivo
                        </div>
                      </label>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="flex-col"
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <button
            disabled={!canCreatePagare()}
            style={{
              border: "none",
              background: "rgba(0,0,0,0.2)",
              borderRadius: "6px",
              color: "white",
              textAlign: "center",
              fontSize: "14px",
              fontWeight: "700",
              height: "35px",
              width: "155px",
            }}
            onClick={(e) => {
              e.preventDefault();
              setModalConfirmationOpen(true);
            }}
          >
            Crear Pagaré
          </button>
        </div>
      </div>
    </LoadingOverlay>
  );
};
