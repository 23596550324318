import { ReactComponent as DownArrow } from "../../../../Assets/common/images/downArrow.svg";
import { ReactComponent as EyeIcon } from "../../../../Assets/common/images/eye.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import { ReactComponent as LeftArrow } from "../../../../Assets/common/images/leftArrow.svg";
import LoadingOverlay from "react-loading-overlay-ts";
import { useEffect, useState } from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useNavigate, useParams } from "react-router-dom";
import {
  cancelPagare,
  changeActiveComponent,
  confirmOtp,
  deletePagare,
  downloadFile,
  downloadZipEvidence,
  getDocumentsPagare,
  getPagareById,
  nullifyPagare,
  resetPagareDetail,
  selectConfirmationId,
  selectCurrentDocumentsPagare,
  selectCurrentPagare,
  selectDownloadingStatus,
  selectLoadingCancellation,
  selectLoadingDeletion,
  selectLoadingDocuments,
  selectLoadingNullification,
  selectLoadingPagares,
  selectOtpLoading,
  selectPagareDownloadZipStatus,
  selectSendAgain,
  selectSentOtp,
  sendOtpConfirm,
} from "../pagareSlice";
import ClipLoader from "react-spinners/ClipLoader";
import {
  LoadingStatus,
  selectClientId,
  selectRoles,
  selectUserEmail,
} from "../../../common/commonSlice";
import {
  getStatusPagareName,
  getTipoPagareName,
} from "../../firma/helpers/utils";
import fileDownload from "js-file-download";
import {
  GeneratedDocumentsPagare,
  LedgerPagare,
  PagareExtraDocumentsInfo,
} from "../pagareAPI";
import {
  base64PDFToBlobUrl,
  formatDateStandar,
} from "../../../../helpers/utils";
import { getActionString } from "../utils";
import Modal, { Styles } from "react-modal";
import OtpInput from "react-otp-input-rc-17";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import es from "date-fns/locale/es";
import format from "date-fns/format";

Modal.setAppElement("#root");
export const DetailPagare = () => {
  registerLocale("es", es as any);
  const customStylesModalDeudores: Styles = {
    content: {
      top: "50%",
      left: "60%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "rgba(255,255,255,1)",
      borderRadius: "20px",
      boxSizing: "border-box",
      padding: "1.2%",
      width: "75%",
      overflow: "auto",
    },
    overlay: {
      background: "rgba(0,0,0,0.3)",
    },
  };

  const customStylesModalAnnotationDetail: Styles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "rgba(255,255,255,1)",
      borderRadius: "20px",
      boxSizing: "border-box",
      padding: "1.2%",
      width: "40%",
      overflow: "auto",
    },
    overlay: {
      background: "rgba(0,0,0,0.5)",
    },
  };

  const customStylesModalNullifyOrCancell: Styles = {
    content: {
      top: "48%",
      left: "60%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "rgba(255,255,255,1)",
      borderRadius: "20px",
      boxSizing: "border-box",
      padding: "1.2%",
      width: "60%",
      overflow: "auto",
    },
    overlay: {
      background: "rgba(0,0,0,0.3)",
    },
  };
  const customStylesModalDelete: Styles = {
    content: {
      top: "48%",
      left: "60%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "rgba(255,255,255,1)",
      borderRadius: "20px",
      boxSizing: "border-box",
      padding: "10px",
      width: "27%",
      overflow: "auto",
    },
    overlay: {
      background: "rgba(0,0,0,0.3)",
    },
  };

  let loadingStatus = useAppSelector(selectLoadingPagares);
  let documentsLoading = useAppSelector(selectLoadingDocuments);
  let downloading = useAppSelector(selectDownloadingStatus);
  let currentPagare = useAppSelector(selectCurrentPagare);
  let documents = useAppSelector(selectCurrentDocumentsPagare);
  const userEmail = useAppSelector(selectUserEmail);
  let roles = useAppSelector(selectRoles);
  let clientId = useAppSelector(selectClientId);
  const pagareZipDownloadingStatus = useAppSelector(
    selectPagareDownloadZipStatus
  );
  const sentOtp = useAppSelector(selectSentOtp);
  let sendAgain = useAppSelector(selectSendAgain);
  const confirmationId = useAppSelector(selectConfirmationId);
  const loadingCancellation = useAppSelector(selectLoadingCancellation);
  const loadingNullification = useAppSelector(selectLoadingNullification);
  const otpLoading = useAppSelector(selectOtpLoading);
  const loadingDeletion = useAppSelector(selectLoadingDeletion);

  const [documentToVisualize, setDocumentToVisualize] = useState<{
    documentBase64: string;
    documentName: string;
    url: string;
  }>({
    documentBase64: "",
    documentName: "",
    url: "",
  });

  const [deudoresModalOpen, setDeudoresModalOpen] = useState(false);
  const [annotationDetailOpen, setAnnotationDetailOpen] =
    useState<LedgerPagare | null>(null);

  //Execute actions
  const [nullifyModalOpen, setNullifyModalOpen] = useState(false);
  const [successNullification, setSuccessNullification] = useState(false);
  const [cancellModalOpen, setCancellModalOpen] = useState(false);
  const [successCancellation, setSuccessCancellation] = useState(false);
  const [cancellationDate, setCancellationDate] = useState("");
  const [confirmCancellation, setConfirmCancellation] = useState(false);
  const [showResend, setShowResend] = useState(false);
  const [beginCounter, setBeginCounter] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [cancellationReason, setCancellationReason] = useState("");
  const [nullificationReason, setNullificationReason] = useState("");

  let navigate = useNavigate();
  let dispatch = useAppDispatch();

  let downloadAtatchment = (fileName: string, index: number) => {
    dispatch(downloadFile({ fileName, index }));
  };

  const canBeEndosado = () => {
    if (currentPagare && roles.includes("pagare.endosos")) {
      if (
        currentPagare.statusData.hasBeenSigned &&
        !currentPagare.statusData.hasBeenCancelled &&
        !currentPagare.statusData.hasBeenNullified &&
        currentPagare.currentClientId == clientId &&
        currentPagare.status !== "BlockedByEndosoValidation" &&
        currentPagare.status !== "Nullified" &&
        currentPagare.status !== "Cancelled"
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const canBeDeleted = () => {
    if (currentPagare && roles.includes("pagare.delete")) {
      if (!currentPagare.statusData.hasBeenSigned) {
        return true;
      } else {
        return false;
      }
    } else {
      false;
    }
  };

  const canBeFilledOut = () => {
    if (currentPagare && roles.includes("pagare.fill")) {
      if (
        currentPagare.statusData.hasBeenSigned &&
        !currentPagare.statusData.hasBeenFilled &&
        !currentPagare.statusData.hasBeenCancelled &&
        !currentPagare.statusData.hasBeenNullified &&
        currentPagare.currentClientId == clientId &&
        currentPagare.type === "Blank" &&
        currentPagare.status !== "Nullified" &&
        currentPagare.status !== "Cancelled" &&
        currentPagare.status !== "BlockedByEndosoValidation"
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const canBeNullified = () => {
    if (currentPagare && roles.includes("pagare.nullify")) {
      if (
        currentPagare.statusData.hasBeenSigned &&
        !currentPagare.statusData.hasBeenFilled &&
        !currentPagare.statusData.hasBeenCancelled &&
        !currentPagare.statusData.hasBeenNullified &&
        !currentPagare.statusData.hasBeenEndosado &&
        currentPagare.status !== "Nullified" &&
        currentPagare.status !== "Cancelled" &&
        currentPagare.status !== "BlockedByEndosoValidation" &&
        currentPagare.status !== "Endosado"
      ) {
        return true;
      }
    } else {
      return false;
    }
  };

  const canBeCancelled = () => {
    if (currentPagare && roles.includes("pagare.cancel")) {
      if (
        currentPagare.statusData.hasBeenSigned &&
        !currentPagare.statusData.hasBeenCancelled &&
        !currentPagare.statusData.hasBeenNullified &&
        currentPagare.status !== "Nullified" &&
        currentPagare.status !== "Cancelled" &&
        currentPagare.status !== "BlockedByEndosoValidation"
      ) {
        return true;
      }
    } else {
      return false;
    }
  };

  const canBeAdministered = () => {
    return (
      canBeFilledOut() ||
      canBeEndosado() ||
      canBeNullified() ||
      canBeCancelled() ||
      canBeDeleted()
    );
  };

  const renderPagareAdmin = () => {
    return (
      <div
        className="flex-row"
        style={{
          height: "10%",
          justifyContent: "space-evenly",
          width: "100%",
          alignItems: "center",
          margin: "10px 0",
        }}
      >
        {canBeFilledOut() && (
          <button
            style={{
              background: "rgba(0,0,0,0.3)",
              borderRadius: "8px",
              color: "white",
              textAlign: "center",
              border: "none",
              fontSize: "14px",
              fontWeight: "600",
              height: "35px",
              padding: "0 20px 0 20px",
            }}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/services/pagares/fill/${currentPagare?.id}`);
            }}
          >
            Diligenciar
          </button>
        )}
        {canBeEndosado() && (
          <button
            style={{
              background: "rgba(0,0,0,0.3)",
              borderRadius: "8px",
              color: "white",
              textAlign: "center",
              border: "none",
              fontSize: "14px",
              fontWeight: "600",
              height: "35px",
              padding: "0 20px 0 20px",
            }}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/services/pagares/endosar/${currentPagare?.id}`);
            }}
          >
            Endosar
          </button>
        )}
        {canBeNullified() && (
          <button
            style={{
              background: "rgba(0,0,0,0.3)",
              borderRadius: "8px",
              color: "white",
              textAlign: "center",
              border: "none",
              fontSize: "14px",
              fontWeight: "600",
              height: "35px",
              padding: "0 20px 0 20px",
            }}
            onClick={(e) => {
              e.preventDefault();
              setNullifyModalOpen(true);
            }}
          >
            Anular
          </button>
        )}
        {canBeCancelled() && (
          <button
            style={{
              background: "rgba(0,0,0,0.3)",
              borderRadius: "8px",
              color: "white",
              textAlign: "center",
              border: "none",
              fontSize: "14px",
              fontWeight: "600",
              height: "35px",
              padding: "0 20px 0 20px",
            }}
            onClick={(e) => {
              e.preventDefault();
              setCancellModalOpen(true);
            }}
          >
            Cancelar
          </button>
        )}
        {canBeDeleted() && (
          <button
            style={{
              background: "rgba(0,0,0,0.3)",
              borderRadius: "8px",
              color: "white",
              textAlign: "center",
              border: "none",
              fontSize: "14px",
              fontWeight: "600",
              height: "35px",
              padding: "0 20px 0 20px",
            }}
            onClick={(e) => {
              e.preventDefault();
              setDeleteModalOpen(true);
            }}
          >
            Eliminar
          </button>
        )}
      </div>
    );
  };

  const canGenerateZip = () => {
    if (currentPagare && roles.includes("pagare.view")) {
      if (
        currentPagare.statusData.hasBeenSigned &&
        currentPagare.currentClientId == clientId
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const getOtherDeudores = () => {
    let otherDeudores = [];
    if (currentPagare && currentPagare?.deudores.length > 1) {
      otherDeudores = currentPagare?.deudores.slice(1);
      return (
        <table style={{ marginTop: "10px", width: "100%" }}>
          <thead>
            <th style={{ color: "black", fontWeight: 600 }}>Nombre</th>
            <th style={{ color: "black", fontWeight: 600 }}>Apellidos</th>
            <th style={{ color: "black", fontWeight: 600 }}>
              No. de Documento
            </th>
            <th style={{ color: "black", fontWeight: 600 }}>Correo</th>
            <th style={{ color: "black", fontWeight: 600 }}>No. Celular</th>
            <th style={{ color: "black", fontWeight: 600 }}>
              ID Identidad Digital
            </th>
          </thead>
          <tbody>
            {otherDeudores.map((deudor, index) => {
              return (
                <tr key={index}>
                  <td
                    style={{
                      color: "black",
                      fontWeight: 500,
                      fontSize: "15px",
                    }}
                  >
                    {deudor.nombre}
                  </td>
                  <td
                    style={{
                      color: "black",
                      fontWeight: 500,
                      fontSize: "15px",
                    }}
                  >
                    {deudor.apellido}
                  </td>
                  <td
                    style={{
                      color: "black",
                      fontWeight: 500,
                      fontSize: "15px",
                    }}
                  >
                    {deudor.identification}
                  </td>
                  <td
                    style={{
                      color: "black",
                      fontWeight: 500,
                      fontSize: "15px",
                    }}
                  >
                    {deudor.email}
                  </td>
                  <td
                    style={{
                      color: "black",
                      fontWeight: 500,
                      fontSize: "15px",
                    }}
                  >
                    {deudor.phoneNumber}
                  </td>
                  <td
                    style={{
                      color: "black",
                      fontWeight: 500,
                      fontSize: "15px",
                    }}
                  >
                    <a
                      href={`/services/coreId/registrations/${deudor.userId}`}
                      target="_blank"
                      style={{
                        color: "black",
                        fontWeight: 500,
                        textDecoration: "none",
                      }}
                    >
                      {" "}
                      {deudor.userId}
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    } else {
      return <div></div>;
    }
  };

  let getDocument = (
    loadingDocuments: LoadingStatus,
    generatedDocuments: GeneratedDocumentsPagare,
    documentType: "Pagare" | "Carta" | "Certificate",
    status: string,
    setDocumentVisualizer: React.Dispatch<
      React.SetStateAction<{
        documentBase64: string;
        documentName: string;
        url: string;
      }>
    >
  ) => {
    switch (loadingDocuments) {
      case "pending":
        return (
          <LoadingOverlay
            active={documentsLoading === "pending"}
            spinner={<ClipLoader color="white" size="100px" />}
          ></LoadingOverlay>
        );
      case "resolved":
        if (
          currentPagare?.status == "Created" ||
          currentPagare?.status == "FillBefore"
        ) {
          return (
            <div
              className="flex-row"
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              No se ha firmado el pagaré
            </div>
          );
        } else {
          if (documentType === "Carta") {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  padding: "7px 0",
                }}
              >
                <div
                  className="flex-row"
                  onClick={(e) => {
                    e.preventDefault();
                    let url = base64PDFToBlobUrl(
                      generatedDocuments.carta.documentBase64
                    );
                    setDocumentVisualizer({
                      documentBase64: generatedDocuments.carta.documentBase64,
                      documentName: generatedDocuments.carta.documentName,
                      url,
                    });
                  }}
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    width: "85%",
                    height: "32px",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    marginBottom: "5px",
                    cursor: "pointer",
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      margin: 0,
                      fontSize: "14px",
                      fontWeight: "600",
                      marginRight: "10px",
                    }}
                  >
                    Visualizar
                  </p>
                  <EyeIcon />
                </div>
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    let url = `data:application/pdf;base64,${generatedDocuments.carta.documentBase64}`;

                    fetch(url)
                      .then((res) => res.blob())
                      .then((blob) => {
                        fileDownload(
                          blob,
                          generatedDocuments.carta.documentName
                        );
                      });
                  }}
                  className="flex-row"
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    width: "85%",
                    height: "32px",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      margin: 0,
                      fontSize: "14px",
                      fontWeight: "600",
                      marginRight: "10px",
                    }}
                  >
                    Descargar
                  </p>
                  <DownArrow />
                </div>
              </div>
            );
          }
          if (documentType === "Certificate") {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  padding: "7px 0",
                }}
              >
                <div
                  className="flex-row"
                  onClick={(e) => {
                    e.preventDefault();
                    let url = base64PDFToBlobUrl(
                      generatedDocuments.certificado.documentBase64
                    );
                    setDocumentVisualizer({
                      documentBase64:
                        generatedDocuments.certificado.documentBase64,
                      documentName: generatedDocuments.certificado.documentName,
                      url,
                    });
                  }}
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    width: "85%",
                    height: "32px",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    marginBottom: "5px",
                    cursor: "pointer",
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      margin: 0,
                      fontSize: "14px",
                      fontWeight: "600",
                      marginRight: "10px",
                    }}
                  >
                    Visualizar
                  </p>
                  <EyeIcon />
                </div>
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    let url = `data:application/pdf;base64,${generatedDocuments.certificado.documentBase64}`;

                    fetch(url)
                      .then((res) => res.blob())
                      .then((blob) => {
                        fileDownload(
                          blob,
                          generatedDocuments.certificado.documentName
                        );
                      });
                  }}
                  className="flex-row"
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    width: "85%",
                    height: "32px",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      margin: 0,
                      fontSize: "14px",
                      fontWeight: "600",
                      marginRight: "10px",
                    }}
                  >
                    Descargar
                  </p>
                  <DownArrow />
                </div>
              </div>
            );
          } else if (documentType == "Pagare") {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  padding: "7px 0",
                }}
              >
                <div
                  className="flex-row"
                  onClick={(e) => {
                    e.preventDefault();
                    let url = base64PDFToBlobUrl(
                      generatedDocuments.pagare.documentBase64
                    );
                    setDocumentVisualizer({
                      documentBase64: generatedDocuments.pagare.documentBase64,
                      documentName: generatedDocuments.pagare.documentName,
                      url,
                    });
                  }}
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    width: "85%",
                    height: "32px",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    marginBottom: "5px",
                    cursor: "pointer",
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      margin: 0,
                      fontSize: "14px",
                      fontWeight: "600",
                      marginRight: "10px",
                    }}
                  >
                    Visualizar
                  </p>
                  <EyeIcon />
                </div>
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    let url = `data:application/pdf;base64,${generatedDocuments.pagare.documentBase64}`;

                    fetch(url)
                      .then((res) => res.blob())
                      .then((blob) => {
                        fileDownload(
                          blob,
                          generatedDocuments.pagare.documentName
                        );
                      });
                  }}
                  className="flex-row"
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    width: "85%",
                    height: "32px",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      margin: 0,
                      fontSize: "14px",
                      fontWeight: "600",
                      marginRight: "10px",
                    }}
                  >
                    Descargar
                  </p>
                  <DownArrow />
                </div>
              </div>
            );
          } else {
            return <></>;
          }
        }
      case "rejected":
        if (status !== "Signed") {
          return (
            <div
              className="flex-row"
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              No se ha firmado el pagaré
            </div>
          );
        } else {
          return (
            <div
              className="flex-row"
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              No pudimos cargar los documentos
            </div>
          );
        }
      default:
        return (
          <>
            <div
              className="flex-row"
              style={{
                background: "rgba(0,0,0,0.3)",
                width: "60%",
                height: "30%",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                marginBottom: "2%",
              }}
            >
              <div style={{ marginRight: "5%" }}>Visualizar</div>
              <EyeIcon />
            </div>
            <div
              onClick={(e) => {
                e.preventDefault();
              }}
              className="flex-row"
              style={{
                background: "rgba(0,0,0,0.3)",
                width: "80%",
                height: "30%",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              <div style={{ marginRight: "5%" }}>Descargar</div>
              <EyeIcon />
            </div>
          </>
        );
    }
  };

  const sendOtpContent = () => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "10px 0",
        }}
      >
        <p
          style={{
            fontSize: "14px",
            color: "black",
            fontWeight: "500",
            textAlign: "center",
            width: "80%",
          }}
        >
          Por razones de seguridad hemos enviado a su correo electrónico{" "}
          <strong>{userEmail}</strong> un código de confirmación, ingreselo a
          continuación.
        </p>

        <OtpInput
          value={otpValue}
          onChange={(otp: any) => {
            setOtpValue(otp);
          }}
          numInputs={5}
          inputStyle={{
            background: "rgba(26, 97, 125,0.2)",
            border: "none",
            height: "47px",
            width: "45px",
            borderRadius: "6px",
            marginRight: "7px",
            color: "black",
          }}
          containerStyle={{
            marginBottom: "15px",
          }}
          isInputNum={true}
        />
        {showResend && (
          <p
            style={{
              fontSize: "14px",
              color: "black",
              fontWeight: "500",
              textAlign: "center",
              width: "80%",
            }}
          >
            El código es válido por 5 minutos, en caso de que no lo encuentre en
            su <strong>bandeja de entrada o spam</strong> puede reenviarlo a
            continuación.
          </p>
        )}
        {showResend && (
          <button
            onClick={(e) => {
              e.preventDefault();
              dispatch(
                sendOtpConfirm({
                  pagareRootId: currentPagare?.id!,
                  sendAgain,
                })
              );
            }}
            className="flex-col text-medium text-bold primary-color"
            disabled={otpLoading === "pending"}
            style={{
              background: "#1A617D",
              width: "30%",
              height: "30px",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
              border: "none",
              margin: "10px 0",
            }}
          >
            {otpLoading === "pending" ? (
              <ClipLoader color="white" size={"20px"} />
            ) : (
              `Re-enviar correo`
            )}
          </button>
        )}
        {otpLoading === "rejected" && (
          <p
            style={{
              fontSize: "14px",
              color: "#FF5C5C",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            El código ingresado es incorrecto
          </p>
        )}
        {loadingCancellation === "rejected" && (
          <p
            style={{
              fontSize: "14px",
              color: "#FF5C5C",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Hubo un error cancelando el pagaré. <br /> Por favor intentelo de
            nuevo más tarde.
          </p>
        )}
        {loadingNullification === "rejected" && (
          <p
            style={{
              fontSize: "14px",
              color: "#FF5C5C",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Hubo un error anulando el pagaré. <br /> Por favor intentelo de
            nuevo más tarde.
          </p>
        )}
      </div>
    );
  };

  const nullifyPagareContent = () => {
    if (sentOtp) {
      return sendOtpContent();
    } else {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            margin: "10px 0",
          }}
        >
          <p style={{ fontSize: "14px", color: "black", fontWeight: "500" }}>
            Esta acción aplica solo cuando, por razones administrativas, se
            define que no se va a adquirir la obligación, es decir, el pagaré
            queda invalidado ya sea porque presenta errores, defectos formales,
            fue emitido incorrectamente, o porque ambas partes acuerdan que no
            debe tener fuerza vinculante. El resultado es que el pagaré queda
            inactivo e invalidado.
            <br />
            <strong>NO </strong> se debe confundir con la{" "}
            <strong>cancelación</strong> de un pagaré, que implica que la
            obligación se ha cumplido, es decir, la deuda se ha saldado o
            extinguido.
            <br />
            Si está seguro de que se trata de una <strong>
              anulación{" "}
            </strong>{" "}
            confirme a continuación, de lo contrario, puede cerrar la ventana y
            seleccionar el proceso de cancelación.
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "12px",
              marginBottom: "15px",
              width: "60%",
              alignSelf: "center",
            }}
          >
            <label
              htmlFor="reason"
              style={{
                width: "100%",
                marginRight: "10px",
                alignSelf: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "start",
              }}
            >
              <p
                style={{
                  fontSize: "13px",
                  color: "#000",
                  fontWeight: "500",
                  textAlign: "start",
                  margin: "0 0 2px 2px",
                }}
              >
                Motivo de anulación (opcional)
              </p>
              <input
                onChange={(e) => {
                  setNullificationReason(e.target.value);
                }}
                id="reason"
                name="reason"
                type="text"
                placeholder="OPCIONAL"
                value={nullificationReason}
                style={{
                  border: "1px solid rgba(0,0,0,0.5)",
                  borderRadius: "6px",
                  background: "transparent",
                  padding: "8px 8px 8px 8px",
                  minHeight: "24px",
                  fontSize: "12px",
                  fontWeight: "500",
                  textAlign: "start",
                  color: "#000",
                  width: "100%",
                }}
              />
            </label>
          </div>
        </div>
      );
    }
  };
  const cancellPagareContent = () => {
    if (sentOtp) {
      return sendOtpContent();
    } else {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "10px 0",
          }}
        >
          <p
            style={{
              fontSize: "14px",
              color: "black",
              fontWeight: "500",
              textAlign: "start",
            }}
          >
            Esta acción se debe realizar cuando el pagaré ha sido cumplido o
            porque la deuda asociada a este pagaré ha sido pagada o extinguida.
            <br />
            <strong>NO </strong> se debe confundir con la{" "}
            <strong>anulación</strong>
            <br />
            Si se requiere la cancelación del pagaré, valide la siguiente
            información y confirme la <strong>cancelación.</strong>{" "}
          </p>
          <h5
            style={{
              fontSize: "14px",
              color: "black",
              marginTop: "10px",
              fontWeight: "700",
              textAlign: "center",
            }}
          >
            {" "}
            Seleccione la fecha en que se cumplió el pagaré
          </h5>
          <div
            style={{
              width: "30%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ReactDatePicker
              locale="es"
              selected={cancellationDate ? new Date(cancellationDate) : null}
              isClearable
              onChange={(date) => {
                if (date) {
                  setCancellationDate(date?.toISOString());
                } else {
                  setCancellationDate("");
                }
              }}
              placeholderText="Seleccionar fecha"
              customInput={
                <input
                  disabled={true}
                  id="name"
                  name="name"
                  type="text"
                  value={
                    cancellationDate
                      ? format(new Date(cancellationDate!), "dd/MM/yyyy")
                      : ""
                  }
                  style={{
                    border: "1px solid rgba(0,0,0,0.5)",
                    borderRadius: "6px",
                    background: "transparent",
                    padding: "7px 0px 7px 0px",
                    minHeight: "24px",
                    fontSize: "14px",
                    fontWeight: "600",
                    textAlign: "center",
                    color: "#000",
                    width: "100%",
                    cursor: "pointer",
                    alignSelf: "center",
                  }}
                />
              }
              minDate={new Date("2020-01-01")}
              maxDate={new Date()}
            />
          </div>
          <div style={{ height: "100px" }}></div>
          <FormControlLabel
            style={{
              color: "#000",
              width: "80%",
              margin: "20px 0 20px 20px ",
              fontFamily: "Open Sans",
            }}
            control={
              <Checkbox
                style={{ color: "#000", alignSelf: "start" }}
                checked={confirmCancellation}
                onChange={() => {
                  setConfirmCancellation(!confirmCancellation);
                }}
              />
            }
            label={
              <span
                style={{
                  fontFamily: "Open Sans",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                Confirmo que la deuda asociada a este pagaré ha sido saldad o
                extinguida.
              </span>
            }
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "12px",
              marginBottom: "15px",
              width: "60%",
              alignSelf: "center",
            }}
          >
            <label
              htmlFor="reason"
              style={{
                width: "100%",
                marginRight: "10px",
                alignSelf: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "start",
              }}
            >
              <p
                style={{
                  fontSize: "13px",
                  color: "#000",
                  fontWeight: "500",
                  textAlign: "start",
                  margin: "0 0 2px 2px",
                }}
              >
                Motivo de cancelación (opcional)
              </p>
              <input
                onChange={(e) => {
                  setCancellationReason(e.target.value);
                }}
                id="reason"
                name="reason"
                type="text"
                placeholder="OPCIONAL"
                value={cancellationReason}
                style={{
                  border: "1px solid rgba(0,0,0,0.5)",
                  borderRadius: "6px",
                  background: "transparent",
                  padding: "8px 8px 8px 8px",
                  minHeight: "24px",
                  fontSize: "12px",
                  fontWeight: "500",
                  textAlign: "start",
                  color: "#000",
                  width: "100%",
                }}
              />
            </label>
          </div>
        </div>
      );
    }
  };

  const confirmButtonCancellOrNullify = () => {
    if (sentOtp) {
      return (
        <button
          disabled={otpLoading === "pending"}
          onClick={(e) => {
            e.preventDefault();
            dispatch(
              confirmOtp({
                code: otpValue,
                pagareRootId: currentPagare?.id!,
              })
            );
          }}
          className="flex-col text-medium text-bold primary-color"
          style={{
            background: "#1A617D",
            width: "35%",
            height: "30px",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "8px",
            border: "none",
          }}
        >
          {otpLoading === "pending" ||
          loadingCancellation === "pending" ||
          loadingNullification === "pending" ? (
            <ClipLoader color="white" size={"20px"} />
          ) : (
            `Confirmar Código`
          )}
        </button>
      );
    } else {
      if (cancellModalOpen) {
        return (
          <button
            disabled={
              !cancellationDate ||
              !confirmCancellation ||
              otpLoading === "pending" ||
              loadingCancellation === "pending"
            }
            onClick={(e) => {
              e.preventDefault();
              dispatch(
                sendOtpConfirm({
                  pagareRootId: currentPagare?.id!,
                  sendAgain,
                })
              );
              setBeginCounter(true);
            }}
            className="flex-col text-medium text-bold primary-color"
            style={{
              background: "#1A617D",
              width: "35%",
              height: "30px",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
              border: "none",
            }}
          >
            {otpLoading === "pending" || loadingCancellation === "pending" ? (
              <ClipLoader color="white" size={"20px"} />
            ) : (
              `Confirmar cancelación`
            )}
          </button>
        );
      } else {
        return (
          <button
            disabled={
              otpLoading === "pending" || loadingNullification === "pending"
            }
            onClick={(e) => {
              e.preventDefault();
              dispatch(
                sendOtpConfirm({
                  pagareRootId: currentPagare?.id!,
                  sendAgain,
                })
              );
              setBeginCounter(true);
            }}
            className="flex-col text-medium text-bold primary-color"
            style={{
              background: "#1A617D",
              width: "35%",
              height: "30px",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
              border: "none",
            }}
          >
            {otpLoading === "pending" || loadingNullification === "pending" ? (
              <ClipLoader color="white" size={"20px"} />
            ) : (
              `Confirmar anulación`
            )}
          </button>
        );
      }
    }
  };

  let { id } = useParams();

  useEffect(() => {
    if ((!currentPagare || currentPagare.id !== id) && id !== "") {
      dispatch(getPagareById(id || ""));
    }

    if (currentPagare && documentsLoading === "idle") {
      dispatch(getDocumentsPagare(currentPagare.id));
    }

    dispatch(changeActiveComponent("none"));

    return () => {};
  }, [id, currentPagare]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (beginCounter) {
        setShowResend(true);
      }
    }, 16000);

    if (confirmationId && confirmationId !== "") {
      if (cancellModalOpen) {
        dispatch(
          cancelPagare({
            idConfirmation: confirmationId,
            cancellationDate: cancellationDate,
            pagareRootId: currentPagare?.id!,
            cancellationReason: cancellationReason,
          })
        );
      } else if (nullifyModalOpen) {
        dispatch(
          nullifyPagare({
            idConfirmation: confirmationId,
            pagareRootId: currentPagare?.id!,
            reason: nullificationReason,
          })
        );
      }
    }

    return () => clearTimeout(timeout);
  }, [beginCounter, confirmationId, sentOtp]);

  useEffect(() => {
    if (loadingCancellation === "resolved") {
      setSuccessCancellation(true);
      setCancellModalOpen(false);
    }
    if (loadingNullification === "resolved") {
      setNullifyModalOpen(false);
      setSuccessNullification(true);
    }
    if (loadingDeletion === "resolved") {
      dispatch(resetPagareDetail());
      navigate("/services/pagares/list");
    }
  }, [loadingCancellation, loadingNullification, loadingDeletion]);

  return (
    <LoadingOverlay
      active={loadingStatus === "pending"}
      spinner={<ClipLoader color="white" size="100px" />}
    >
      <Modal
        isOpen={deudoresModalOpen}
        style={customStylesModalDeudores}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          setDeudoresModalOpen(false);
        }}
      >
        <div className="flex-col">
          <div
            className="text-large text-bold"
            style={{ textAlign: "center", fontSize: "18px" }}
          >
            Codeudores
          </div>
          {getOtherDeudores()}
          <div
            className="flex-row"
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <div
              onClick={(e) => {
                e.preventDefault();
                setDeudoresModalOpen(false);
              }}
              className="flex-col text-medium text-bold primary-color"
              style={{
                background: "#1A617D",
                width: "20%",
                height: "30px",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              Cerrar
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={nullifyModalOpen || cancellModalOpen}
        style={customStylesModalNullifyOrCancell}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          setNullifyModalOpen(false);
          setCancellModalOpen(false);
        }}
      >
        <div
          className="flex-col"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <div
            className="text-large text-bold"
            style={{
              textAlign: "center",
              fontSize: "18px",
            }}
          >
            {nullifyModalOpen ? "Anulación de Pagaré" : "Cancelación de Pagaré"}
          </div>
          {nullifyModalOpen ? nullifyPagareContent() : cancellPagareContent()}
          <div
            className="flex-row"
            style={{
              width: "80%",
              justifyContent: "space-evenly",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <button
              onClick={(e) => {
                e.preventDefault();
                setNullifyModalOpen(false);
                setCancellModalOpen(false);
                setShowResend(false);
                setBeginCounter(false);
                setOtpValue("");
              }}
              className="flex-col text-medium text-bold"
              style={{
                background: "transparent",
                width: "35%",
                height: "30px",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                border: "1px solid #1A617D",
                color: "#1A617D",
              }}
            >
              Cerrar ventana
            </button>
            {confirmButtonCancellOrNullify()}
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={successCancellation || successNullification}
        style={customStylesModalDelete}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          dispatch(resetPagareDetail());
          setSuccessCancellation(false);
          setSuccessNullification(false);
          navigate(0);
        }}
      >
        <div
          className="flex-col"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <div
            className="text-large text-bold"
            style={{
              textAlign: "center",
              fontSize: "18px",
              margin: "20px 0px",
            }}
          >
            {successCancellation
              ? "Se ha cancelado el pagaré con exito"
              : "Se ha anulado el pagaré con exito"}
          </div>
          <div
            className="flex-row"
            style={{
              width: "80%",
              justifyContent: "space-evenly",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <button
              onClick={(e) => {
                e.preventDefault();
                dispatch(resetPagareDetail());
                setSuccessCancellation(false);
                setSuccessNullification(false);
                navigate(0);
              }}
              className="flex-col text-medium text-bold"
              style={{
                background: "transparent",
                width: "60%",
                height: "35px",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                border: "1px solid #1A617D",
                color: "#1A617D",
              }}
            >
              Cerrar ventana
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={deleteModalOpen}
        style={customStylesModalDelete}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          setDeleteModalOpen(false);
        }}
      >
        <div
          className="flex-col"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <div
            className="text-large text-bold"
            style={{
              textAlign: "center",
              fontSize: "18px",
            }}
          >
            Eliminación del pagaré
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              margin: "10px 0 40px 0",
            }}
          >
            <p style={{ fontSize: "13px", color: "black", fontWeight: "500" }}>
              Debido a que el pagaré no se ha firmado, no se ha generado la
              obligación y puede eliminarlo de la lista.
              <br />
              <br />
              <strong>Es importante aclarar,</strong> que si se elimina el
              pagaré, va a quedar invalidado y no se va a poder firmar, por lo
              que , si el pagaré está pendiente de firma, bloqueará a los
              deudores de firmarlo.
              <br />
              <br />
              <strong>Si está seguro</strong> que puede eliminarlo confirme a
              continuación.
            </p>
          </div>
          {loadingDeletion === "rejected" && (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <p
                style={{
                  fontSize: "13px",
                  color: "#D32F2F",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                Ha ocurrido un error al eliminar el pagaré, por favor intente
                nuevamente en unos minutos
              </p>
            </div>
          )}
          <div
            className="flex-row"
            style={{
              width: "80%",
              justifyContent: "space-evenly",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <button
              onClick={(e) => {
                e.preventDefault();
                setDeleteModalOpen(false);
              }}
              className="flex-col text-medium text-bold"
              style={{
                background: "transparent",
                width: "40%",
                height: "35px",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                border: "1px solid #1A617D",
                color: "#1A617D",
              }}
            >
              Cerrar ventana
            </button>
            <button
              disabled={loadingDeletion === "pending"}
              onClick={(e) => {
                e.preventDefault();
                dispatch(deletePagare(currentPagare?.id!));
              }}
              className="flex-col text-medium text-bold primary-color"
              style={{
                background: "#1A617D",
                width: "40%",
                height: "35px",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                border: "none",
              }}
            >
              {loadingDeletion === "pending" ? (
                <ClipLoader color="white" size={"20px"} />
              ) : (
                `Eliminar`
              )}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={annotationDetailOpen !== null}
        style={customStylesModalAnnotationDetail}
        onRequestClose={() => setAnnotationDetailOpen(null)}
        shouldCloseOnEsc={true}
      >
        <div className="flex-col" style={{ padding: "20px" }}>
          <div
            className="text-large text-bold"
            style={{ textAlign: "center", fontSize: "18px" }}
          >
            Detalle de {getActionString(annotationDetailOpen?.action || "")}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "20px 0",
              width: "80%",
            }}
          >
            {annotationDetailOpen &&
              annotationDetailOpen.descripcionDetail &&
              Object.entries(annotationDetailOpen.descripcionDetail).map(
                ([key, value]) => {
                  if (key === "username") {
                    return (
                      <div
                        key={key}
                        style={{
                          width: "100%",
                          textAlign: "start",
                          fontSize: "14px",
                          color: "black",
                          marginBottom: "2px",
                        }}
                      >
                        <strong>
                          Nombre de usuario que ejecutó la acción:{" "}
                        </strong>
                        {value}
                      </div>
                    );
                  } else if (key === "userEmail") {
                    return (
                      <div
                        key={key}
                        style={{
                          width: "100%",
                          textAlign: "start",
                          fontSize: "14px",
                          color: "black",
                          marginBottom: "2px",
                        }}
                      >
                        <strong>
                          Correo del usuario que ejecutó la acción:{" "}
                        </strong>
                        {value}
                      </div>
                    );
                  } else if (key === "userId") {
                    return (
                      <div
                        key={key}
                        style={{
                          width: "100%",
                          textAlign: "start",
                          fontSize: "14px",
                          color: "black",
                          marginBottom: "2px",
                        }}
                      >
                        <strong>
                          ID del usuario de backoffice que ejecutó la acción:{" "}
                        </strong>
                        {value}
                      </div>
                    );
                  } else if (key === "codeUsed") {
                    return (
                      <div
                        key={key}
                        style={{
                          width: "100%",
                          textAlign: "start",
                          fontSize: "14px",
                          color: "black",
                          marginBottom: "2px",
                        }}
                      >
                        <strong>
                          Código de autenticación para realizar la acción :{" "}
                        </strong>
                        {value}
                      </div>
                    );
                  } else if (key === "paymentDate") {
                    return (
                      <div
                        key={key}
                        style={{
                          width: "100%",
                          textAlign: "start",
                          fontSize: "14px",
                          color: "black",
                          marginBottom: "2px",
                        }}
                      >
                        <strong>Fecha de deuda saldada: {value}</strong>
                        {value}
                      </div>
                    );
                  } else {
                    return <div></div>;
                  }
                }
              )}
          </div>
          <div
            className="flex-row"
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <div
              onClick={(e) => {
                e.preventDefault();
                setAnnotationDetailOpen(null);
              }}
              className="flex-col text-medium text-bold primary-color"
              style={{
                background: "#1A617D",
                width: "20%",
                height: "30px",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              Cerrar
            </div>
          </div>
        </div>
      </Modal>
      {currentPagare && loadingStatus === "resolved" ? (
        <div
          className="flex-col"
          style={{ height: "100%", overflow: "auto", width: "100%" }}
        >
          <div
            className="flex-row"
            style={{
              height: "5%",
              justifyContent: "end",
              alignItems: "center",
              marginRight: "5%",
            }}
          >
            <div
              onClick={(e) => {
                e.preventDefault();
                dispatch(resetPagareDetail());
                navigate(-1);
              }}
              className="flex-row"
              style={{
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <LeftArrow style={{ marginRight: "15px" }} />
              <div className="text-bold text-xx-large primary-color">
                Regresar a lista de pagarés
              </div>
            </div>
          </div>
          <div className="flex-row" style={{ height: "100%", width: "100%" }}>
            <div
              className="flex-col"
              style={{
                height: "85%",
                width: "50%",
                justifyContent: "start",
                padding: "1%",
              }}
            >
              <div
                className="flex-row"
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "33%",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "rgba(0,0,0,0.2)",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "38px",
                      backgroundColor: "rgba(0,0,0,0.2)",
                      borderRadius: "8px 8px 0px 0px",
                    }}
                  >
                    <h6
                      style={{
                        color: "rgba(255,255,255,0.6)",
                        textAlign: "center",
                        margin: "0px",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      Estado
                    </h6>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "70px",
                      borderRadius: "0px 0px 8px 8px",
                    }}
                  >
                    <h6
                      style={{
                        color: "white",
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "600",
                        margin: "0px",
                      }}
                    >
                      {getStatusPagareName(currentPagare.status)}
                    </h6>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "46%",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "rgba(0,0,0,0.2)",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "38px",
                      backgroundColor: "rgba(0,0,0,0.2)",
                      borderRadius: "8px 8px 0px 0px",
                    }}
                  >
                    <h6
                      style={{
                        color: "rgba(255,255,255,0.6)",
                        textAlign: "center",
                        margin: "0px",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      Tipo de Pagaré
                    </h6>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "70px",
                      borderRadius: "0px 0px 8px 8px",
                    }}
                  >
                    <h6
                      style={{
                        color: "white",
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "600",
                        margin: "0px",
                        padding: "0px 5px 0px 5px",
                      }}
                    >
                      {getTipoPagareName(currentPagare.type)}
                    </h6>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "18%",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "rgba(0,0,0,0.2)",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "38px",
                      backgroundColor: "rgba(0,0,0,0.2)",
                      borderRadius: "8px 8px 0px 0px",
                    }}
                  >
                    <h6
                      style={{
                        color: "rgba(255,255,255,0.6)",
                        textAlign: "center",
                        margin: "0px",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      Deudores
                    </h6>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "70px",
                      borderRadius: "0px 0px 8px 8px",
                    }}
                  >
                    <h6
                      style={{
                        color: "white",
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "600",
                        margin: "0px",
                      }}
                    >
                      {currentPagare.deudores.length}
                    </h6>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  backgroundColor: "rgba(0,0,0,0.2)",
                  borderRadius: "8px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "38px",
                    backgroundColor: "rgba(0,0,0,0.2)",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px 8px 0px 0px",
                  }}
                >
                  <h6
                    style={{
                      color: "rgba(255,255,255,0.6)",
                      margin: "0px",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Documentos
                  </h6>
                </div>
                {documents &&
                currentPagare &&
                currentPagare.statusData.hasBeenSigned ? (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: "8px",
                      justifyContent: "space-evenly",
                    }}
                  >
                    {documents &&
                      documents.pagare &&
                      documents.pagare.documentBase64 && (
                        <div
                          style={{
                            display: "flex",
                            width: "30%",
                            flexDirection: "column",
                            alignItems: "center",
                            backgroundColor: "rgba(0,0,0,0.2)",
                            borderRadius: "8px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "33px",
                              backgroundColor: "rgba(0,0,0,0.2)",
                              borderRadius: "8px 8px 0px 0px",
                            }}
                          >
                            <h6
                              style={{
                                color: "rgba(255,255,255,0.4)",
                                textAlign: "center",
                                margin: "0px",
                                fontSize: "14px",
                                fontWeight: "600",
                              }}
                            >
                              Pagaré
                            </h6>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "0px 0px 8px 8px",
                            }}
                          >
                            {getDocument(
                              documentsLoading,
                              documents!,
                              "Pagare",
                              currentPagare.status,
                              setDocumentToVisualize
                            )}
                          </div>
                        </div>
                      )}
                    {documents &&
                      documents.carta &&
                      documents.carta.documentBase64 && (
                        <div
                          style={{
                            display: "flex",
                            width: "33%",
                            flexDirection: "column",
                            alignItems: "center",
                            backgroundColor: "rgba(0,0,0,0.2)",
                            borderRadius: "8px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "33px",
                              backgroundColor: "rgba(0,0,0,0.2)",
                              borderRadius: "8px 8px 0px 0px",
                            }}
                          >
                            <h6
                              style={{
                                color: "rgba(255,255,255,0.4)",
                                textAlign: "center",
                                margin: "0px",
                                fontSize: "14px",
                                fontWeight: "600",
                              }}
                            >
                              Carta de Instrucciones
                            </h6>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "0px 0px 8px 8px",
                            }}
                          >
                            {getDocument(
                              documentsLoading,
                              documents!,
                              "Carta",
                              currentPagare.status,
                              setDocumentToVisualize
                            )}
                          </div>
                        </div>
                      )}
                    {documents &&
                      documents.certificado &&
                      documents.certificado.documentBase64 && (
                        <div
                          style={{
                            display: "flex",
                            width: "30%",
                            flexDirection: "column",
                            alignItems: "center",
                            backgroundColor: "rgba(0,0,0,0.2)",
                            borderRadius: "8px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "43px",
                              backgroundColor: "rgba(0,0,0,0.2)",
                              borderRadius: "8px 8px 0px 0px",
                            }}
                          >
                            <h6
                              style={{
                                color: "rgba(255,255,255,0.4)",
                                textAlign: "center",
                                margin: "0px",
                                fontSize: "14px",
                                fontWeight: "600",
                              }}
                            >
                              Certificado de traza del Pagaré
                            </h6>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "0px 0px 8px 8px",
                            }}
                          >
                            {getDocument(
                              documentsLoading,
                              documents!,
                              "Certificate",
                              currentPagare.status,
                              setDocumentToVisualize
                            )}
                          </div>
                        </div>
                      )}
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100px",
                      width: "100%",
                    }}
                  >
                    <h4 style={{ color: "rgba(255,255,255,0.8)" }}>
                      Documentos no se han firmado
                    </h4>
                  </div>
                )}
                {documents?.extraDocuments &&
                  documents.extraDocuments.length > 0 && (
                    <div
                      style={{
                        width: "95%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: "rgba(0,0,0,0.2)",
                        borderRadius: "8px",
                        marginBottom: "20px",
                        alignSelf: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "20px",
                          backgroundColor: "rgba(0,0,0,0.2)",
                          borderRadius: "8px 8px 0px 0px",
                          marginBottom: "2px",
                        }}
                      >
                        <h6
                          style={{
                            color: "rgba(255,255,255,0.4)",
                            textAlign: "center",
                            margin: "0px",
                            fontSize: "12px",
                            fontWeight: "600",
                          }}
                        >
                          Documentos Auxiliares
                        </h6>
                      </div>
                      <table style={{ width: "100%" }}>
                        <thead
                          style={{
                            background: "rgba(0,0,0,0.2)",
                            height: "28px",
                            borderCollapse: "collapse",
                            overflow: "auto",
                          }}
                        >
                          <tr>
                            <th></th>
                            <th
                              style={{
                                color: "rgba(255,255,255,0.4)",
                                textAlign: "start",
                                fontSize: "12px",
                                width: "70%",
                                paddingLeft: "15px",
                              }}
                            >
                              Nombre Documento
                            </th>
                            <th
                              style={{
                                color: "rgba(255,255,255,0.4)",
                                textAlign: "center",
                                fontSize: "12px",
                                width: "30%",
                              }}
                            >
                              Descargar
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody style={{ height: "25px" }}>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          {documents.extraDocuments.map(
                            (
                              document: PagareExtraDocumentsInfo,
                              index: number
                            ) => (
                              <tr
                                key={index}
                                style={{
                                  color: "white",
                                  textAlign: "start",
                                  fontSize: "12px",
                                }}
                              >
                                <td></td>
                                <td
                                  style={{
                                    color: "white",
                                    textAlign: "start",
                                    fontSize: "12px",
                                    width: "70%",
                                    height: "25px",
                                    paddingLeft: "15px",
                                  }}
                                >
                                  {document.name}
                                </td>
                                <td
                                  style={{
                                    color: "white",
                                    textAlign: "center",
                                    fontSize: "12px",
                                    width: "30%",
                                    height: "25px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    downloadAtatchment(
                                      document.fileName,
                                      index
                                    );
                                  }}
                                >
                                  {downloading[index] === "pending" ? (
                                    <ClipLoader color="white" size="20px" />
                                  ) : (
                                    <DownArrow />
                                  )}
                                </td>
                                <td></td>
                              </tr>
                            )
                          )}
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                {canGenerateZip() && (
                  <div
                    style={{
                      width: "95%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "end",
                      alignSelf: "center",
                    }}
                  >
                    <button
                      style={{
                        background: "rgba(0,0,0,0.4)",
                        color: "white",
                        border: "none",
                        borderRadius: "8px",
                        height: "45px",
                        width: "171px",
                        cursor: "pointer",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "10px",
                      }}
                      onClick={() => {
                        if (currentPagare) {
                          dispatch(downloadZipEvidence(currentPagare.id));
                        }
                      }}
                    >
                      <h6
                        style={{
                          margin: "0px",
                          fontSize: "14px",
                          fontWeight: "600",
                          textAlign: "center",
                        }}
                      >
                        {pagareZipDownloadingStatus === "pending" ? (
                          <ClipLoader color="white" size="20px" />
                        ) : (
                          "Generar carpeta de envidencias"
                        )}
                      </h6>
                    </button>
                  </div>
                )}
              </div>
              <div
                className="flex-col"
                style={{
                  width: "96%",
                  height: "35%",
                  marginRight: "1%",
                  marginTop: "2%",
                  marginBottom: "2%",
                }}
              ></div>
            </div>
            <div
              className="flex-col"
              style={{
                height: "85%",
                width: "50%",
                justifyContent: "start",
                padding: "1%",
              }}
            >
              {currentPagare.deudores &&
                currentPagare.deudores.length > 0 &&
                currentPagare.deudores[0] && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "8px",
                      alignItems: "center",
                      backgroundColor: "rgba(0,0,0,0.2)",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: "8px 8px 0px 0px",
                        background: "rgba(0,0,0,0.2)",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "30px",
                      }}
                    >
                      <h6
                        style={{
                          color: "rgba(255,255,255,0.6)",
                          fontSize: "14px",
                          margin: "0px",
                        }}
                      >
                        Datos de deudores
                      </h6>
                    </div>
                    <div
                      style={{
                        width: "96%",
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: "8px",
                        background: "rgba(0,0,0,0.2)",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "10px 0px",
                        padding: "10px 10px",
                      }}
                    >
                      {currentPagare.deudores &&
                        currentPagare.deudores.length > 0 &&
                        currentPagare.deudores[0] && (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-evenly",
                            }}
                          >
                            <div
                              style={{
                                width: "50%",
                                textAlign: "start",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {currentPagare.deudores[0].nombre && (
                                <h5
                                  style={{
                                    color: "white",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Nombre:
                                </h5>
                              )}
                              {currentPagare.deudores[0].apellido && (
                                <h5
                                  style={{
                                    color: "white",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Apellido:
                                </h5>
                              )}
                              {currentPagare.deudores[0].identification && (
                                <h5
                                  style={{
                                    color: "white",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                  }}
                                >
                                  No. de documento:
                                </h5>
                              )}
                              {currentPagare.deudores[0].phoneNumber && (
                                <h5
                                  style={{
                                    color: "white",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Teléfono:
                                </h5>
                              )}
                              {currentPagare.deudores[0].email && (
                                <h5
                                  style={{
                                    color: "white",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Correo Electrónico:
                                </h5>
                              )}
                              {currentPagare.deudores[0].userId && (
                                <h5
                                  style={{
                                    color: "white",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                  }}
                                >
                                  ID Identidad Digital:
                                </h5>
                              )}
                            </div>
                            <div
                              style={{
                                width: "50%",
                                textAlign: "start",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {currentPagare.deudores[0].nombre && (
                                <h5
                                  style={{
                                    color: "white",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {currentPagare.deudores[0].nombre}
                                </h5>
                              )}
                              {currentPagare.deudores[0].apellido && (
                                <h5
                                  style={{
                                    color: "white",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {currentPagare.deudores[0].apellido}
                                </h5>
                              )}
                              {currentPagare.deudores[0].identification && (
                                <h5
                                  style={{
                                    color: "white",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {currentPagare.deudores[0].identification}
                                </h5>
                              )}
                              {currentPagare.deudores[0].phoneNumber && (
                                <h5
                                  style={{
                                    color: "white",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {currentPagare.deudores[0].phoneNumber}
                                </h5>
                              )}
                              {currentPagare.deudores[0].email && (
                                <h5
                                  style={{
                                    color: "white",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {currentPagare.deudores[0].email}
                                </h5>
                              )}
                              {currentPagare.deudores[0].userId && (
                                <h5
                                  style={{
                                    color: "white",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                  }}
                                >
                                  <a
                                    href={`/services/coreId/registrations/${currentPagare.deudores[0].userId}`}
                                    target="_blank"
                                    style={{
                                      color: "white",
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      textDecoration: "none",
                                    }}
                                  >
                                    {currentPagare.deudores[0].userId}
                                  </a>
                                </h5>
                              )}
                            </div>
                          </div>
                        )}
                    </div>
                    {currentPagare.deudores.length > 1 && (
                      <div
                        style={{
                          width: "96%",
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: "10px",
                          justifyContent: "end",
                        }}
                      >
                        <button
                          style={{
                            border: "none",
                            background: "rgba(0,0,0,0.2)",
                            borderRadius: "6px",
                            color: "white",
                            textAlign: "center",
                            fontSize: "14px",
                            fontWeight: "600",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "36px",
                            padding: "0 20px 0 20px",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            setDeudoresModalOpen(true);
                          }}
                        >
                          Ver Codeudores
                        </button>
                      </div>
                    )}
                  </div>
                )}

              {canBeAdministered() && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "8px",
                    alignItems: "center",
                    backgroundColor: "rgba(0,0,0,0.2)",
                    margin: "10px 0",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "8px 8px 0px 0px",
                      background: "rgba(0,0,0,0.2)",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "35px",
                    }}
                  >
                    <h6
                      style={{
                        color: "rgba(255,255,255,0.6)",
                        fontSize: "14px",
                        margin: "0px",
                      }}
                    >
                      Administración del pagaré
                    </h6>
                  </div>
                  <p
                    style={{
                      width: "90%",
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "rgba(255,255,255,0.8)",
                      textAlign: "start",
                      marginTop: "10px",
                    }}
                  >
                    Aquí podrás realizar acciones sobre este pagaré, no todas
                    las acciones están disponibles en todos los estados. A
                    continuación se encuentran las acciones posibles en el
                    estado actual.
                    <br />
                    Cada botón te llevará al menú dedicado para completar la
                    acción, con el respectivo proceso de confirmación.
                  </p>
                  {renderPagareAdmin()}
                </div>
              )}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "8px",
                  alignItems: "center",
                  marginTop: "10px",
                  backgroundColor: "rgba(0,0,0,0.2)",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "8px 8px 0px 0px",
                    background: "rgba(0,0,0,0.2)",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "35px",
                    marginBottom: "10px",
                  }}
                >
                  <h6
                    style={{
                      color: "rgba(255,255,255,0.6)",
                      fontSize: "14px",
                      margin: "0px",
                    }}
                  >
                    Historial Pagaré
                  </h6>
                </div>

                <table style={{ width: "100%" }}>
                  <thead
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      height: "28px",
                      borderCollapse: "collapse",
                      overflow: "auto",
                    }}
                  >
                    <tr>
                      <th></th>
                      <th
                        style={{
                          color: "rgba(255,255,255,0.4)",
                          textAlign: "start",
                          fontSize: "12px",
                          width: "20%",
                          paddingLeft: "15px",
                        }}
                      >
                        Acción
                      </th>
                      <th
                        style={{
                          color: "rgba(255,255,255,0.4)",
                          textAlign: "center",
                          fontSize: "12px",
                          width: "30%",
                        }}
                      >
                        Fecha
                      </th>

                      <th
                        style={{
                          color: "rgba(255,255,255,0.4)",
                          textAlign: "center",
                          fontSize: "12px",
                          width: "50%",
                        }}
                      >
                        Descripción
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody style={{ height: "25px" }}>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {currentPagare.ledgerAnnotations.map(
                      (annotation: LedgerPagare, index: number) => (
                        <tr
                          key={index}
                          style={{
                            color: "white",
                            textAlign: "start",
                            fontSize: "12px",
                          }}
                        >
                          <td></td>
                          <td
                            style={{
                              color: "white",
                              textAlign: "start",
                              fontSize: "12px",
                              width: "20%",
                              height: "25px",
                              paddingLeft: "15px",
                            }}
                          >
                            {getActionString(annotation.action)}
                          </td>
                          <td
                            style={{
                              color: "white",
                              textAlign: "center",
                              fontSize: "12px",
                              width: "30%",
                              height: "25px",
                              paddingLeft: "15px",
                            }}
                          >
                            {formatDateStandar(annotation.fecha)}
                          </td>
                          <td
                            style={{
                              color: "white",
                              textAlign: "start",
                              fontSize: "12px",
                              width: "50%",
                              height: "25px",
                              paddingLeft: "15px",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <p style={{ margin: "0px", width: "70%" }}>
                                {annotation.descripcion
                                  ? annotation.descripcion
                                  : "Sin descripción"}
                              </p>

                              {annotation.descripcionDetail && (
                                <button
                                  style={{
                                    border: "none",
                                    textAlign: "center",
                                    borderRadius: "8px",
                                    fontSize: "10px",
                                    background: "rgba(0,0,0,0.4)",
                                    paddingRight: "10px",
                                    paddingLeft: "10px",
                                    color: "white",
                                    height: "20px",
                                    fontWeight: "600",
                                  }}
                                  onClick={(e) => {
                                    setAnnotationDetailOpen(annotation);
                                  }}
                                >
                                  Ver detalle
                                </button>
                              )}
                            </div>
                          </td>
                          <td></td>
                        </tr>
                      )
                    )}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {documentToVisualize.documentBase64 !== "" ? (
              <div
                className="flex-col"
                style={{
                  position: "absolute",
                  left: "1%",
                  top: "10%",
                  height: "100%",
                  width: "100%",
                }}
              >
                <button
                  onClick={() =>
                    setDocumentToVisualize({
                      documentBase64: "",
                      documentName: "",
                      url: "",
                    })
                  }
                  style={{
                    border: "none",
                    textAlign: "center",
                    borderRadius: "8px",
                    fontSize: "25px",
                    background: "#1A617D",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingRight: "10%",
                    paddingLeft: "10%",
                    color: "white",
                  }}
                >
                  Cerrar <span style={{ textAlign: "end" }}>X</span>
                </button>
                <object
                  data={documentToVisualize.url}
                  type="application/pdf"
                  width="100%"
                  height="100%"
                >
                  {" "}
                  <p>
                    Tu navegador no permite visualizar PDFs puedes descargar el
                    documento aquí
                    <a
                      onClick={() => {
                        fetch(documentToVisualize.url)
                          .then((res) => res.blob())
                          .then((blob) => {
                            fileDownload(
                              blob,
                              "Documento.pdf",
                              "application/pdf"
                            );
                          });
                      }}
                    >
                      Descargar
                    </a>
                  </p>
                </object>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-huge text-bold primary-color">
            {loadingStatus === "rejected"
              ? "No existe el registro con id proporcionado"
              : ""}
          </h1>
        </div>
      )}
    </LoadingOverlay>
  );
};
