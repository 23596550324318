import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import Modal, { Styles } from "react-modal";
import OtpInput from "react-otp-input-rc-17";
import { useNavigate, useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import isEmail from "validator/lib/isEmail";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { ReactComponent as LeftArrow } from "../../../../Assets/common/images/leftArrow.svg";
import { currencyFormat, unFormatCurrency } from "../../../../helpers/utils";
import { DropDownPagare } from "../../../common/components/Btns/DropDown";
import { SmallSquare } from "../../firma/detail/components/Squares";
import {
  getStatusPagareName,
  getTipoPagareName,
} from "../../firma/helpers/utils";
import {
  changeActiveComponent,
  changeEndosoStep,
  confirmOtp,
  endosar,
  getPagareById,
  getPagareOwner,
  resetFields,
  resetPagareDetail,
  selectActivePagareComponent,
  selectConfirmationId,
  selectCurrentPagare,
  selectCurrentPagareOwner,
  selectEndosado,
  selectEndoso,
  selectEndosoStep,
  selectLoadingFields,
  selectLoadingPagareOwner,
  selectLoadingPagares,
  selectOtpLoading,
  selectSendAgain,
  selectSentOtp,
  sendOtpConfirm,
  setEndosante,
  setEndosatario,
  setEndoso,
} from "../pagareSlice";
import { InputText } from "./components/EndosoInputs";

const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    height: "30%",
    width: "30%",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "2%",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};
const customStylesModalDeudores: Styles = {
  content: {
    top: "50%",
    left: "60%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "1.2%",
    width: "75%",
    overflow: "auto",
  },
  overlay: {
    background: "rgba(0,0,0,0.3)",
  },
};
Modal.setAppElement("#root");

export const PagareEndoso = () => {
  let loadingStatus = useAppSelector(selectLoadingPagares);
  let loadingStatusFields = useAppSelector(selectLoadingFields);
  let currentPagare = useAppSelector(selectCurrentPagare);
  let loadingOwner = useAppSelector(selectLoadingPagareOwner);
  let currentOwner = useAppSelector(selectCurrentPagareOwner);
  let sentOtp = useAppSelector(selectSentOtp);
  let otpLoading = useAppSelector(selectOtpLoading);
  let sendAgain = useAppSelector(selectSendAgain);
  let confirmationId = useAppSelector(selectConfirmationId);
  let activeComponent = useAppSelector(selectActivePagareComponent);
  let endoso = useAppSelector(selectEndoso);
  let endosado = useAppSelector(selectEndosado);
  let endosoStep = useAppSelector(selectEndosoStep);

  const [otpValue, setOtpValue] = useState("");
  const [showResend, setShowResend] = useState(false);
  const [beginCounter, setBeginCounter] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deudoresModalOpen, setDeudoresModalOpen] = useState(false);

  const closeModal = () => {
    setModalIsOpen(false);
    dispatch(resetPagareDetail());
    navigate(-1);
  };

  let navigate = useNavigate();
  let dispatch = useAppDispatch();

  const confirmDisabled = () => {
    if (endosoStep === "endosante") {
      if (!isEmail(endoso.endosante?.email)) {
        return true;
      }
      if (endoso.endosante.naturalPerson) {
        if (
          !endoso.endosante.identification ||
          !endoso.endosante.identificationType
        ) {
          return true;
        }
      } else {
        if (
          !endoso.endosante.idLegalRepresentative ||
          !endoso.endosante.idTypeLegalRepresentative ||
          !endoso.endosante.identification ||
          !endoso.endosante.identificationType
        ) {
          return true;
        }
      }
      return false;
    } else if (endosoStep === "endosatario") {
      if (!isEmail(endoso.endosatario?.email)) {
        return true;
      }
      if (endoso.endosatario.naturalPerson) {
        if (
          !endoso.endosatario.identification ||
          !endoso.endosatario.identificationType
        ) {
          return true;
        }
      } else {
        if (
          !endoso.endosatario.idLegalRepresentative ||
          !endoso.endosatario.idTypeLegalRepresentative ||
          !endoso.endosatario.identification ||
          !endoso.endosatario.identificationType
        ) {
          return true;
        }
      }
      return false;
    } else {
      return true;
    }
  };

  const getFormEndoso = () => {
    if (!currentOwner || loadingOwner === "pending") {
      return (
        <LoadingOverlay
          active={true}
          spinner={<ClipLoader color="white" size="50px" />}
        />
      );
    } else {
      if (endosoStep === "endosante") {
        return (
          <div
            className="flex-col"
            style={{
              width: "100%",
              height: "100%",
              marginBottom: "1%",
            }}
          >
            <div
              className="flex-col"
              style={{
                background: "rgba(0,0,0,0.4)",
                height: "29px",
                textAlignLast: "center",
                alignItems: "center",
                justifyContent: "center",
                boxSizing: "border-box",
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <div
                className="text-normal text-medium"
                style={{ color: "white" }}
              >
                Datos del endosante
              </div>
            </div>
            <div
              className="flex-col"
              style={{
                color: "white",
                background: "rgba(0,0,0,0.2)",
                boxSizing: "border-box",
                padding: "2%",
                display: "flex",
                flexDirection: "column",

                borderRadius: "0px 0px 8px 8px ",
              }}
            >
              <div
                style={{
                  color: "white",
                  fontSize: "14px",
                  fontWeight: "500",
                  textAlign: "start",
                  margin: "0 0 2% 5px",
                }}
              >
                Diligencie cuidadosamente la información del{" "}
                <strong>endosante</strong> es decir, la persona o entidad que
                está realizando el traspaso del pagaré.
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                  }}
                >
                  <DropDownPagare
                    key={"endosante Tipo de persona"}
                    title="Tipo de persona"
                    defaultValue={{
                      value: "",
                      label: "Seleccione una opción",
                    }}
                    valueKey={
                      endoso.endosante.naturalPerson ? "Natural" : "Juridica"
                    }
                    options={[
                      { label: "Natural", value: "Natural" },
                      { label: "Jurídica", value: "Juridica" },
                    ]}
                    onChange={(e) => {
                      dispatch(
                        setEndosante({
                          ...endoso.endosante,
                          naturalPerson: e?.value == "Natural" ? true : false,
                          identificationType:
                            e?.value == "Juridica" ? "nit" : "cc",
                        })
                      );
                    }}
                  />
                  <DropDownPagare
                    key={"endosante Tipo de documento"}
                    invisible={!endoso.endosante.naturalPerson}
                    title="Tipo de documento"
                    defaultValue={{
                      value: "",
                      label: "Seleccione una opción",
                    }}
                    options={[
                      { label: "Cedula de ciudadanía", value: "cc" },
                      { label: "Pasaporte", value: "pasaporte" },
                    ]}
                    valueKey={endoso.endosante.identificationType}
                    onChange={(e) => {
                      dispatch(
                        setEndosante({
                          ...endoso.endosante,
                          identificationType: e?.value!,
                        })
                      );
                    }}
                  />
                  <InputText
                    label="No. de Documento"
                    value={endoso.endosante.identification}
                    onChange={(e) => {
                      const reg = new RegExp(/^\d+$/);
                      if (
                        reg.test(e.target.value) === true ||
                        e.target.value == ""
                      ) {
                        dispatch(
                          setEndosante({
                            ...endoso.endosante,
                            identification: e.target.value,
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                  }}
                >
                  <InputText
                    label="Correo electrónico"
                    value={endoso.endosante.email}
                    invisible={!endoso.endosante.naturalPerson}
                    onChange={(e) => {
                      dispatch(
                        setEndosante({
                          ...endoso.endosante,
                          email: e.target.value,
                        })
                      );
                    }}
                  />
                </div>
              </div>
              {endoso.endosante.naturalPerson ? (
                ""
              ) : (
                <div
                  style={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "500",
                    textAlign: "start",
                    margin: "2% 0 2% 5px",
                  }}
                >
                  Debido a que escogió a una persona jurídica como endosante,
                  diligencie cuidadosamente la información del representante
                  legal de la entidad, con esta información verificaremos la
                  identidad del endosante.
                </div>
              )}
              <div
                style={{
                  textAlign: "center",
                  fontWeight: "600",
                  fontSize: "14px",
                  margin: "10px 0 10px 0",
                  color: "rgba(255,255,255,0.5)",
                  height: !endoso.endosante.naturalPerson ? undefined : "0%",
                  visibility: !endoso.endosante.naturalPerson
                    ? "visible"
                    : "hidden",
                }}
              >
                Datos Representante legal
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  overflow: "auto",
                  height: !endoso.endosante.naturalPerson ? "180px" : "0",
                  alignItems: "start",
                  visibility: !endoso.endosante.naturalPerson
                    ? "visible"
                    : "hidden",
                }}
              >
                <DropDownPagare
                  key={"endosante Tipo de documento representante"}
                  title="Tipo de documento"
                  invisible={endoso.endosante.naturalPerson}
                  defaultValue={{
                    value: "",
                    label: "Seleccione una opción",
                  }}
                  options={[
                    { label: "Cedula de ciudadanía", value: "cc" },
                    { label: "Pasaporte", value: "pasaporte" },
                  ]}
                  onChange={(e) => {
                    dispatch(
                      setEndosante({
                        ...endoso.endosante,
                        idTypeLegalRepresentative: e?.value!,
                      })
                    );
                  }}
                />
                <InputText
                  label="No. de Documento"
                  invisible={endoso.endosante.naturalPerson}
                  value={endoso.endosante.idLegalRepresentative}
                  onChange={(e) => {
                    const reg = new RegExp(/^\d+$/);
                    if (
                      reg.test(e.target.value) === true ||
                      e.target.value == ""
                    ) {
                      dispatch(
                        setEndosante({
                          ...endoso.endosante,
                          idLegalRepresentative: e.target.value,
                        })
                      );
                    }
                  }}
                />
                <InputText
                  label="Correo electrónico"
                  value={endoso.endosante.email}
                  invisible={endoso.endosante.naturalPerson}
                  onChange={(e) => {
                    dispatch(
                      setEndosante({
                        ...endoso.endosante,
                        email: e.target.value,
                      })
                    );
                  }}
                  errorMessage={
                    isEmail(endoso.endosante.email)
                      ? ""
                      : "Ingrese un correo válido"
                  }
                />
              </div>

              <div
                className="flex-row"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <button
                  disabled={confirmDisabled()}
                  style={{
                    border: "none",
                    background: "rgba(0,0,0,0.2)",
                    borderRadius: "6px",
                    color: "white",
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: "700",
                    height: "35px",
                    width: "155px",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(changeEndosoStep({ endosoStep: "endosatario" }));
                  }}
                >
                  Siguiente
                </button>
              </div>
            </div>
          </div>
        );
      } else if (endosoStep === "endosatario" || endosoStep === "confirm") {
        return (
          <div
            className="flex-col"
            style={{
              width: "100%",
              height: "100%",
              marginBottom: "1%",
            }}
          >
            <div
              className="flex-col"
              style={{
                background: "rgba(0,0,0,0.4)",
                height: "29px",
                textAlignLast: "center",
                alignItems: "center",
                justifyContent: "center",
                boxSizing: "border-box",
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <div
                className="text-normal text-medium"
                style={{ color: "white" }}
              >
                Datos del endosatario
              </div>
            </div>
            <div
              className="flex-col"
              style={{
                color: "white",
                background: "rgba(0,0,0,0.2)",
                boxSizing: "border-box",
                padding: "2%",
                display: "flex",
                flexDirection: "column",
                borderRadius: "0px 0px 8px 8px ",
              }}
            >
              <div
                style={{
                  color: "white",
                  fontSize: "14px",
                  fontWeight: "500",
                  textAlign: "start",
                  margin: "0 0 2% 5px",
                }}
              >
                Diligencie cuidadosamente la información del{" "}
                <strong>endosatario</strong> es decir la persona o entidad a
                quien se le va a transferir el pagaré
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                  }}
                >
                  <DropDownPagare
                    key={"endosatario Tipo de persona"}
                    title="Tipo de persona"
                    defaultValue={{
                      value: "",
                      label: "Seleccione una opción",
                    }}
                    valueKey={
                      endoso.endosatario.naturalPerson ? "Natural" : "Juridica"
                    }
                    options={[
                      { label: "Natural", value: "Natural" },
                      { label: "Jurídica", value: "Juridica" },
                    ]}
                    onChange={(e) => {
                      dispatch(
                        setEndosatario({
                          ...endoso.endosatario,
                          naturalPerson: e?.value == "Natural" ? true : false,
                          identificationType:
                            e?.value == "Juridica" ? "nit" : "cc",
                        })
                      );
                    }}
                  />
                  <DropDownPagare
                    key={"endosatario Tipo de documento"}
                    invisible={!endoso.endosatario.naturalPerson}
                    title="Tipo de documento"
                    defaultValue={{
                      value: "",
                      label: "Seleccione una opción",
                    }}
                    options={[
                      { label: "Cedula de ciudadanía", value: "cc" },
                      { label: "Pasaporte", value: "pasaporte" },
                    ]}
                    valueKey={endoso.endosatario.identificationType}
                    onChange={(e) => {
                      dispatch(
                        setEndosatario({
                          ...endoso.endosatario,
                          identificationType: e?.value!,
                        })
                      );
                    }}
                  />
                  <InputText
                    label="No. de Documento"
                    value={endoso.endosatario.identification}
                    onChange={(e) => {
                      const reg = new RegExp(/^\d+$/);
                      if (
                        reg.test(e.target.value) === true ||
                        e.target.value == ""
                      ) {
                        dispatch(
                          setEndosatario({
                            ...endoso.endosatario,
                            identification: e.target.value,
                          })
                        );
                      }
                    }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                  }}
                >
                  <InputText
                    label="Valor de compra"
                    value={currencyFormat(endoso.sellValue)}
                    onChange={(e) => {
                      const reg = new RegExp(/^\d+$/);
                      let value = unFormatCurrency(e.target.value);
                      if (reg.test(value) === true || value == "") {
                        dispatch(
                          setEndoso({
                            ...endoso,
                            sellValue:
                              value == "" ? 0 : Number.parseFloat(value),
                          })
                        );
                      }
                    }}
                  />
                  <div
                    className="flex-col"
                    style={{
                      justifyContent: "space-evenly",
                      marginLeft: "5px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Con responsabilidad
                    </div>
                    <div
                      className="flex-row"
                      style={{
                        alignItems: "center",
                        height: "100%",
                        justifyContent: "start",
                      }}
                    >
                      <FormControlLabel
                        style={{ height: "20px" }}
                        control={<Radio size="small" onClick={() => {}} />}
                        label={
                          <div className="text-small text-normal primary-color">
                            Si
                          </div>
                        }
                        checked={endoso.withResponsability}
                        onChange={(e) => {
                          dispatch(
                            setEndoso({
                              ...endoso,
                              withResponsability: true,
                            })
                          );
                        }}
                      />
                      <FormControlLabel
                        style={{ height: "17px" }}
                        control={<Radio size="small" onClick={() => {}} />}
                        label={
                          <div className="text-small text-normal primary-color">
                            No
                          </div>
                        }
                        checked={!endoso.withResponsability}
                        onChange={(e) => {
                          dispatch(
                            setEndoso({
                              ...endoso,
                              withResponsability: false,
                            })
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
                <InputText
                  label="Correo electrónico"
                  value={endoso.endosatario.email}
                  invisible={!endoso.endosatario.naturalPerson}
                  onChange={(e) => {
                    dispatch(
                      setEndosatario({
                        ...endoso.endosatario,
                        email: e.target.value,
                      })
                    );
                  }}
                />
              </div>
              {endoso.endosatario.naturalPerson ? (
                ""
              ) : (
                <div
                  style={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "500",
                    textAlign: "start",
                    margin: "2% 0 2% 5px",
                  }}
                >
                  Debido a que escogió a una persona jurídica como endosatario,
                  diligencie cuidadosamente la información del representante
                  legal de la entidad, con esta información verificaremos la
                  identidad del endosatario.
                </div>
              )}
              <div
                style={{
                  textAlign: "center",
                  fontWeight: "600",
                  fontSize: "14px",
                  margin: "10px 0 10px 0",
                  color: "rgba(255,255,255,0.5)",
                  height: !endoso.endosatario.naturalPerson ? undefined : "0%",
                  visibility: !endoso.endosatario.naturalPerson
                    ? "visible"
                    : "hidden",
                }}
              >
                Datos Representante legal
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  overflow: "auto",
                  height: !endoso.endosatario.naturalPerson ? "180px" : "0",
                  alignItems: "start",
                  visibility: !endoso.endosatario.naturalPerson
                    ? "visible"
                    : "hidden",
                }}
              >
                <DropDownPagare
                  key={"endosatario Tipo de documento Rep"}
                  title="Tipo de documento"
                  invisible={endoso.endosatario.naturalPerson}
                  defaultValue={{
                    value: "",
                    label: "Seleccione una opción",
                  }}
                  options={[
                    { label: "Cedula de ciudadanía", value: "cc" },
                    { label: "Pasaporte", value: "pasaporte" },
                  ]}
                  onChange={(e) => {
                    dispatch(
                      setEndosatario({
                        ...endoso.endosatario,
                        idTypeLegalRepresentative: e?.value!,
                      })
                    );
                  }}
                />
                <InputText
                  label="No. de Documento"
                  invisible={endoso.endosatario.naturalPerson}
                  value={endoso.endosatario.idLegalRepresentative}
                  onChange={(e) => {
                    const reg = new RegExp(/^\d+$/);
                    if (
                      reg.test(e.target.value) === true ||
                      e.target.value == ""
                    ) {
                      dispatch(
                        setEndosatario({
                          ...endoso.endosatario,
                          idLegalRepresentative: e.target.value,
                        })
                      );
                    }
                  }}
                />
                <InputText
                  label="Correo electrónico"
                  value={endoso.endosatario.email}
                  invisible={endoso.endosatario.naturalPerson}
                  onChange={(e) => {
                    dispatch(
                      setEndosatario({
                        ...endoso.endosatario,
                        email: e.target.value,
                      })
                    );
                  }}
                  errorMessage={
                    isEmail(endoso.endosatario.email)
                      ? ""
                      : "Ingrese un correo válido"
                  }
                />
              </div>

              <div
                className="flex-row"
                style={{
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  marginTop: "20px",
                  visibility: sentOtp ? "hidden" : "visible",
                }}
              >
                <button
                  style={{
                    border: "none",
                    background: "rgba(0,0,0,0.2)",
                    borderRadius: "6px",
                    color: "white",
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: "700",
                    height: "35px",
                    width: "155px",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(changeEndosoStep({ endosoStep: "endosante" }));
                  }}
                >
                  Volver
                </button>
                <button
                  disabled={confirmDisabled()}
                  style={{
                    border: "none",
                    background: "rgba(0,0,0,0.2)",
                    borderRadius: "6px",
                    color: "white",
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: "700",
                    height: "35px",
                    width: "155px",
                  }}
                  onClick={(e) => {
                    e.preventDefault();

                    dispatch(
                      sendOtpConfirm({
                        pagareRootId: currentPagare?.id!,
                        sendAgain,
                      })
                    );
                    setBeginCounter(true);
                  }}
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        );
      }
    }
  };

  const getOtherDeudores = () => {
    let otherDeudores = [];
    if (currentPagare && currentPagare?.deudores.length > 1) {
      otherDeudores = currentPagare?.deudores.slice(1);
      return (
        <table style={{ marginTop: "10px", width: "100%" }}>
          <thead>
            <th style={{ color: "black", fontWeight: 600 }}>Nombre</th>
            <th style={{ color: "black", fontWeight: 600 }}>Apellidos</th>
            <th style={{ color: "black", fontWeight: 600 }}>
              No. de Documento
            </th>
            <th style={{ color: "black", fontWeight: 600 }}>Correo</th>
            <th style={{ color: "black", fontWeight: 600 }}>No. Celular</th>
            <th style={{ color: "black", fontWeight: 600 }}>
              ID Identidad Digital
            </th>
          </thead>
          <tbody>
            {otherDeudores.map((deudor, index) => {
              return (
                <tr key={index}>
                  <td
                    style={{
                      color: "black",
                      fontWeight: 500,
                      fontSize: "15px",
                    }}
                  >
                    {deudor.nombre}
                  </td>
                  <td
                    style={{
                      color: "black",
                      fontWeight: 500,
                      fontSize: "15px",
                    }}
                  >
                    {deudor.apellido}
                  </td>
                  <td
                    style={{
                      color: "black",
                      fontWeight: 500,
                      fontSize: "15px",
                    }}
                  >
                    {deudor.identification}
                  </td>
                  <td
                    style={{
                      color: "black",
                      fontWeight: 500,
                      fontSize: "15px",
                    }}
                  >
                    {deudor.email}
                  </td>
                  <td
                    style={{
                      color: "black",
                      fontWeight: 500,
                      fontSize: "15px",
                    }}
                  >
                    {deudor.phoneNumber}
                  </td>
                  <td
                    style={{
                      color: "black",
                      fontWeight: 500,
                      fontSize: "15px",
                    }}
                  >
                    {deudor.userId}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    } else {
      return <div></div>;
    }
  };

  let { id } = useParams();

  useEffect(() => {
    if ((!currentPagare || currentPagare.id !== id) && id !== "") {
      dispatch(getPagareById(id || ""));
    }

    if (confirmationId && confirmationId !== "") {
      dispatch(endosar(confirmationId));
    }

    if (endosado) {
      setModalIsOpen(true);
    }

    if (!currentOwner && loadingOwner === "idle" && id) {
      dispatch(getPagareOwner(id));
    }

    let timer = setTimeout(() => {
      if (beginCounter) {
        setShowResend(true);
      }
    }, 16000);
    if (activeComponent == "none") {
      dispatch(changeActiveComponent("none"));
    }
    return () => {
      clearTimeout(timer);
    };
  }, [id, showResend, beginCounter, confirmationId, sentOtp]);

  return (
    <LoadingOverlay
      active={
        loadingStatus === "pending" ||
        loadingStatusFields === "pending" ||
        otpLoading === "pending"
      }
      spinner={<ClipLoader color="white" size="100px" />}
    >
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStylesModal}
        contentLabel="Error de búsqueda"
        shouldCloseOnOverlayClick={true}
      >
        <div
          className="flex-col"
          style={{
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div
            className="text-medium text-normal"
            style={{
              color: "black",
              marginBottom: "10px",
              paddingLeft: "10%",
              paddingRight: "10%",
            }}
          >
            Se ha diligenciado el pagaré de manera correcta y segura.
          </div>
          <button
            className="text-medium text-bold primary-color"
            style={{
              border: "none",
              textAlign: "center",
              borderRadius: "8px",
              background: "#1A617D",
              paddingTop: "5px",
              paddingBottom: "5px",
              paddingRight: "10%",
              paddingLeft: "10%",
            }}
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          >
            Cerrar
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={deudoresModalOpen}
        style={customStylesModalDeudores}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          setDeudoresModalOpen(false);
        }}
      >
        <div className="flex-col">
          <div
            className="text-large text-bold"
            style={{ textAlign: "center", fontSize: "18px" }}
          >
            Codeudores
          </div>
          {getOtherDeudores()}
          <div
            className="flex-row"
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <div
              onClick={(e) => {
                e.preventDefault();
                setDeudoresModalOpen(false);
              }}
              className="flex-col text-medium text-bold primary-color"
              style={{
                background: "#1A617D",
                width: "20%",
                height: "30px",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              Cerrar
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStylesModal}
        contentLabel="Error de búsqueda"
        shouldCloseOnOverlayClick={true}
      >
        <div
          className="flex-col"
          style={{
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div
            className="text-medium text-normal"
            style={{
              color: "black",
              marginBottom: "10px",
              paddingLeft: "10%",
              paddingRight: "10%",
            }}
          >
            Se ha iniciado el proceso de endoso del pagaré.
          </div>
          <div
            className="text-medium text-normal"
            style={{
              color: "black",
              marginBottom: "10px",
              paddingLeft: "10%",
              paddingRight: "10%",
            }}
          >
            Para revisar el estado del endoso cierra esta ventana y revisa el
            estado del pagaré.
          </div>
          <button
            className="text-medium text-bold primary-color"
            style={{
              border: "none",
              textAlign: "center",
              borderRadius: "8px",
              background: "#1A617D",
              paddingTop: "5px",
              paddingBottom: "5px",
              paddingRight: "10%",
              paddingLeft: "10%",
            }}
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          >
            Cerrar
          </button>
        </div>
      </Modal>
      {currentPagare && loadingStatus === "resolved" ? (
        <div
          className="flex-col"
          style={{ height: "100%", overflow: "auto", width: "100%" }}
        >
          <div
            className="flex-row"
            style={{
              height: "10%",
              justifyContent: "end",
              alignItems: "center",
              marginRight: "5%",
            }}
          >
            <div
              onClick={(e) => {
                e.preventDefault();
                dispatch(resetFields());
                navigate(-1);
              }}
              className="flex-row"
              style={{
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <LeftArrow style={{ marginRight: "15px" }} />
              <div className="text-bold text-xx-large primary-color">
                Regresar al detalle del pagaré
              </div>
            </div>
          </div>
          <div className="flex-row" style={{ height: "100%", width: "100%" }}>
            <div
              className="flex-col"
              style={{
                height: "85%",
                width: "44%",
                justifyContent: "start",
                padding: "1%",
              }}
            >
              <div
                className="flex-row"
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "33%",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "rgba(0,0,0,0.2)",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "38px",
                      backgroundColor: "rgba(0,0,0,0.2)",
                      borderRadius: "8px 8px 0px 0px",
                    }}
                  >
                    <h6
                      style={{
                        color: "rgba(255,255,255,0.4)",
                        textAlign: "center",
                        margin: "0px",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      Estado
                    </h6>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "70px",
                      borderRadius: "0px 0px 8px 8px",
                    }}
                  >
                    <h6
                      style={{
                        color: "white",
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "600",
                        margin: "0px",
                      }}
                    >
                      {getStatusPagareName(currentPagare.status)}
                    </h6>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "46%",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "rgba(0,0,0,0.2)",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "38px",
                      backgroundColor: "rgba(0,0,0,0.2)",
                      borderRadius: "8px 8px 0px 0px",
                    }}
                  >
                    <h6
                      style={{
                        color: "rgba(255,255,255,0.4)",
                        textAlign: "center",
                        margin: "0px",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      Tipo de Pagaré
                    </h6>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "70px",
                      borderRadius: "0px 0px 8px 8px",
                    }}
                  >
                    <h6
                      style={{
                        color: "white",
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "600",
                        margin: "0px",
                        padding: "0px 5px 0px 5px",
                      }}
                    >
                      {getTipoPagareName(currentPagare.type)}
                    </h6>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "18%",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "rgba(0,0,0,0.2)",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "38px",
                      backgroundColor: "rgba(0,0,0,0.2)",
                      borderRadius: "8px 8px 0px 0px",
                    }}
                  >
                    <h6
                      style={{
                        color: "rgba(255,255,255,0.4)",
                        textAlign: "center",
                        margin: "0px",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      Deudores
                    </h6>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "70px",
                      borderRadius: "0px 0px 8px 8px",
                    }}
                  >
                    <h6
                      style={{
                        color: "white",
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "600",
                        margin: "0px",
                      }}
                    >
                      {currentPagare.deudores.length}
                    </h6>
                  </div>
                </div>
              </div>

              {currentPagare.deudores && currentPagare.deudores.length > 0 && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "8px",
                    alignItems: "center",
                    backgroundColor: "rgba(0,0,0,0.2)",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "8px 8px 0px 0px",
                      background: "rgba(0,0,0,0.2)",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "30px",
                    }}
                  >
                    <h6
                      style={{
                        color: "rgba(255,255,255,0.4)",
                        fontSize: "14px",
                        margin: "0px",
                      }}
                    >
                      Datos de deudores
                    </h6>
                  </div>
                  <div
                    style={{
                      width: "96%",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "8px",
                      background: "rgba(0,0,0,0.2)",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "10px 0px",
                      padding: "10px 10px",
                    }}
                  >
                    {currentPagare.deudores &&
                      currentPagare.deudores.length > 0 && (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <div
                            style={{
                              width: "50%",
                              textAlign: "start",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {currentPagare.deudores[0].nombre && (
                              <h5
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                              >
                                Nombre:
                              </h5>
                            )}
                            {currentPagare.deudores[0].apellido && (
                              <h5
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                              >
                                Apellido:
                              </h5>
                            )}
                            {currentPagare.deudores[0].identification && (
                              <h5
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                              >
                                No. de documento:
                              </h5>
                            )}
                            {currentPagare.deudores[0].phoneNumber && (
                              <h5
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                              >
                                Teléfono:
                              </h5>
                            )}
                            {currentPagare.deudores[0].email && (
                              <h5
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                              >
                                Correo Electrónico:
                              </h5>
                            )}
                            {currentPagare.deudores[0].userId && (
                              <h5
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                              >
                                ID Identidad Digital:
                              </h5>
                            )}
                          </div>
                          <div
                            style={{
                              width: "50%",
                              textAlign: "start",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {currentPagare.deudores[0].nombre && (
                              <h5
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                }}
                              >
                                {currentPagare.deudores[0].nombre}
                              </h5>
                            )}
                            {currentPagare.deudores[0].apellido && (
                              <h5
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                }}
                              >
                                {currentPagare.deudores[0].apellido}
                              </h5>
                            )}
                            {currentPagare.deudores[0].identification && (
                              <h5
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                }}
                              >
                                {currentPagare.deudores[0].identification}
                              </h5>
                            )}
                            {currentPagare.deudores[0].phoneNumber && (
                              <h5
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                }}
                              >
                                {currentPagare.deudores[0].phoneNumber}
                              </h5>
                            )}
                            {currentPagare.deudores[0].email && (
                              <h5
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                }}
                              >
                                {currentPagare.deudores[0].email}
                              </h5>
                            )}
                            {currentPagare.deudores[0].userId && (
                              <h5
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                }}
                              >
                                {currentPagare.deudores[0].userId}
                              </h5>
                            )}
                          </div>
                        </div>
                      )}
                  </div>
                  {currentPagare.deudores.length > 1 && (
                    <div
                      style={{
                        width: "96%",
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "10px",
                        justifyContent: "end",
                      }}
                    >
                      <button
                        style={{
                          border: "none",
                          background: "rgba(0,0,0,0.2)",
                          borderRadius: "6px",
                          color: "white",
                          textAlign: "center",
                          fontSize: "14px",
                          fontWeight: "600",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "36px",
                          padding: "0 20px 0 20px",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          setDeudoresModalOpen(true);
                        }}
                      >
                        Ver Codeudores
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div
              className="flex-col"
              style={{
                height: "95%",
                width: "54%",
                justifyContent: "start",
                padding: "1%",
              }}
            >
              {getFormEndoso()}
            </div>
          </div>
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-huge text-bold primary-color">
            {loadingStatus === "rejected"
              ? "No existe el registro con id proporcionado"
              : ""}
          </h1>
        </div>
      )}
    </LoadingOverlay>
  );
};
